import styled, { css, keyframes } from 'styled-components'
import { colors } from 'assets/common/colors'
import { StyledCenteredContainer } from 'assets/common/mixin'

const { white, black } = colors

const moveAnimation0 = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  20% {
    transform: translateY(0);
    opacity: 1;
  }
  60% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`

const moveAnimation1 = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  10% {
    transform: translateY(-100%);
    opacity: 0;
  }
  30% {
    transform: translateY(0);
    opacity: 1;
  }
  70% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`

const moveAnimation2 = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  20% {
    transform: translateY(-100%);
    opacity: 0;
  }
  40% {
    transform: translateY(0);
    opacity: 1;
  }
  80% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`

const mobileMoveAnimation0 = keyframes`
  0% {
    transform: translateX(-150%);
    opacity: 0;
  }
  20% {
    transform: translateX(0);
    opacity: 1;
  }
  60% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(150%);
    opacity: 0;
  }
`

const mobileMoveAnimation1 = keyframes`
  0% {
    transform: translateX(-150%);
    opacity: 0;
  }
  10% {
    transform: translateX(-150%);
    opacity: 0;
  }
  30% {
    transform: translateX(0);
    opacity: 1;
  }
  70% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(150%);
    opacity: 0;
  }
`

const mobileMoveAnimation2 = keyframes`
  0% {
    transform: translateX(-150%);
    opacity: 0;
  }
  20% {
    transform: translateX(-150%);
    opacity: 0;
  }
  40% {
    transform: translateX(0);
    opacity: 1;
  }
  80% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(150%);
    opacity: 0;
  }
`

const animations = [moveAnimation0, moveAnimation1, moveAnimation2]
const mobileAnimations = [
  mobileMoveAnimation0,
  mobileMoveAnimation1,
  mobileMoveAnimation2
]

export const StyledClientsContainer = styled.div`
  background: ${white};
  color: ${black};
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  min-height: 26.5rem;

  p {
    text-align: center;
  }

  @media (max-width: 1024px) {
    padding: 6rem;
    min-height: 45rem;
  }

  @media (max-width: 500px) {
    padding: 3rem;
    min-height: 30rem;
  }
`
export const StyledLogosContainer = styled(StyledCenteredContainer)`
  img {
    height: 20rem;
    width: 20rem;
  }
`

export const StyledClientsListContainer = styled.div`
  display: flex;
  gap: 15rem;
  justify-content: center;
  align-items: center;

  @media (max-width: 1440px) {
    gap: 2rem;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 0;
    min-height: 30rem;
  }
`

export const StyledClientLogo = styled.img`
  animation: ${({ index }) => css`
    ${animations[index]} 3s ease-in-out infinite
  `};

  @media (max-width: 768px) {
    animation: ${({ index }) => css`
      ${mobileAnimations[index]} 3s ease-in-out infinite
    `};
  }
`
