import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import {
  StyledButton,
  StyledGradientButton,
  StyledRound
} from './Button.styled'

const Button = ({
  handleClick,
  textButton,
  isGradient = false,
  isBefore = false,
  isDownArrow = false,
  isNoArrow = false,
  isDisabled = false,
  whiteBg,
  darkBg
}) => {
  let textButtonWithArrow

  if (isBefore) {
    textButtonWithArrow = `← ${'   '} ${textButton} `
  }
  if (!isBefore) {
    textButtonWithArrow = `${textButton} ${'   '}  →`
  }
  if (isDownArrow) {
    textButtonWithArrow = `${textButton} ${'   '} ↓`
  }
  if (isNoArrow) {
    textButtonWithArrow = textButton
  }

  const rippleMotion = (color) => {
    return {
      rest: {
        width: 0,
        background: color,
        opacity: 0.3,
        transition: {
          duration: 0.1,
          ease: 'easeInOut'
        }
      },
      hover: {
        width: '100%',
        background: color,
        overflow: 'hidden',
        opacity: 1,
        borderRadius: '100%',
        transition: {
          duration: 0.3,
          ease: 'easeInOut'
        }
      }
    }
  }

  const MotionStyledGradientButton = motion(StyledGradientButton)
  const MotionStyledButton = motion(StyledButton)

  return isGradient ? (
    <MotionStyledGradientButton
      onClick={handleClick}
      whiteBg={whiteBg}
      darkBg={darkBg}
      disabled={isDisabled}
      whileHover="hover"
      initial="rest"
      animate="rest"
      // className="button"
    >
      <StyledRound
        variants={rippleMotion(
          `linear-gradient(83.1deg, #5ab5ea 8.18%, #a264d7 37.76%, #f3b243 62.4%, #ff557d 98.32%)`
        )}
      />
      <span style={{ zIndex: 1 }}>{textButtonWithArrow}</span>
    </MotionStyledGradientButton>
  ) : (
    <MotionStyledButton
      whiteBg={whiteBg}
      darkBg={darkBg}
      onClick={handleClick}
      disabled={isDisabled}
      whileHover="hover"
      initial="rest"
      animate="rest"
      // className="button"
    >
      <StyledRound variants={rippleMotion(darkBg ? 'black' : 'white')} />
      <span style={{ zIndex: 1 }}>{textButtonWithArrow}</span>
    </MotionStyledButton>
  )
}

Button.defaultProps = {
  handleClick: () => {},
  isGradient: false,
  isBefore: false,
  isDownArrow: false,
  isDisabled: false,
  isNoArrow: false,
  whiteBg: false,
  darkBg: false
}

Button.propTypes = {
  handleClick: PropTypes.func,
  textButton: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isGradient: PropTypes.bool,
  isBefore: PropTypes.bool,
  isDownArrow: PropTypes.bool,
  isNoArrow: PropTypes.bool,
  whiteBg: PropTypes.bool,
  darkBg: PropTypes.bool
}

export default Button
