import { useContext, useState, useRef, useEffect, useCallback } from 'react'
import { Context } from 'store'
import { useIntersection, useWindowSize } from 'sebn-library'
import {
  HelmetSEO,
  ImageWithTextOverlay,
  LazyLoaded,
  Dropdown
} from 'components'
import { ReactComponent as LazyLoadIcon } from 'assets/images/lazyload.svg'
import {
  StyledWorkBackground,
  StyledWorkWrapper,
  StyledWorkTitleContainer,
  StyledWorkHeroContainer,
  StyledImageContainer,
  StyledWorkArchiveContainer,
  StyledWorkArchive,
  StyledWorkArchiveImageContainer
} from './Work.styled'

const helmetCopy = {
  title: 'Work portfolio | Cheil Benelux',
  description: `We've worked with some of the best. Take a look at the work we created and the results we've achieved.`,
  keywords:
    'cheil benelux portfolio, cheil cases, cheil work, cheil werk, award winning'
}

const Work = () => {
  const { width } = useWindowSize()

  const {
    state: { portfolio }
  } = useContext(Context)

  const [visibleItems, setVisibleItems] = useState(7)
  const [selectedOption, setSelectedOption] = useState(null)
  const [groupedPortfolio, setGroupedPortfolio] = useState(portfolio)

  const timeoutRef = useRef(null)

  const groupPortfolioByTag = useCallback(
    (tag) => {
      return portfolio.filter((item) => {
        return item.tag && item.tag.value === tag
      })
    },
    [portfolio]
  )

  useEffect(() => {
    setGroupedPortfolio(
      selectedOption ? groupPortfolioByTag(selectedOption.value) : portfolio
    )
  }, [selectedOption, groupPortfolioByTag, portfolio])

  const shownItems = groupedPortfolio.filter((item) => item.hidden !== true)

  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 4)
  }

  const handleButtonHover = () => {
    timeoutRef.current = setTimeout(() => {
      if (visibleItems < portfolio.length) {
        handleLoadMore()
      }
    }, 10)
  }

  const handleButtonLeave = () => {
    clearTimeout(timeoutRef.current)
  }

  const intersectionRef = useRef()
  const intersection = useIntersection(intersectionRef)

  useEffect(() => {
    if (intersection) {
      handleLoadMore()
    }
  }, [intersection])

  return (
    <StyledWorkBackground>
      <StyledWorkWrapper>
        <HelmetSEO copy={helmetCopy} />
        <StyledWorkTitleContainer>
          <h2>Let&apos;s see the work</h2>
          {false && <Dropdown select={{ selectedOption, setSelectedOption }} />}
        </StyledWorkTitleContainer>
        {shownItems.length > 0 && (
          <StyledWorkHeroContainer>
            {shownItems.slice(0, 3).map((item, index) => (
              <StyledImageContainer key={item._id} position={index}>
                <ImageWithTextOverlay
                  item={item}
                  isHeroImage={
                    width > 700 && index === 0 && shownItems.length >= 3
                  }
                />
              </StyledImageContainer>
            ))}
          </StyledWorkHeroContainer>
        )}

        {shownItems.length > 3 && (
          <StyledWorkArchiveContainer>
            <h4>More from the archive:</h4>
            <StyledWorkArchive>
              {shownItems.slice(3, visibleItems).map((item) => (
                <StyledWorkArchiveImageContainer key={item._id}>
                  <LazyLoaded>
                    <ImageWithTextOverlay item={item} />
                  </LazyLoaded>
                </StyledWorkArchiveImageContainer>
              ))}

              {visibleItems < groupedPortfolio.length && (
                <LazyLoadIcon
                  ref={intersectionRef}
                  onClick={handleLoadMore}
                  onMouseEnter={handleButtonHover}
                  onMouseLeave={handleButtonLeave}
                />
              )}
            </StyledWorkArchive>
          </StyledWorkArchiveContainer>
        )}
      </StyledWorkWrapper>
    </StyledWorkBackground>
  )
}

export default Work
