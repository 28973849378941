import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'components'
import { SubTitle1 } from 'assets/common/typography'
import {
  StyledPageNotFoundWrapper,
  StyledPageNotFoundContainer
} from './PageNotFound.styled'

const PageNotFound = () => {
  const navigate = useNavigate()

  return (
    <StyledPageNotFoundWrapper>
      <StyledPageNotFoundContainer>
        <h1>Nothing to see here... 😵️</h1>
        <SubTitle1>
          The page you were looking for couldn&apos;t be found. The page could
          be removed or you misspelled the word while searching for it.
        </SubTitle1>
      </StyledPageNotFoundContainer>
      <Button
        isGradient
        handleClick={() => navigate('/')}
        textButton="Back to homepage"
      />
    </StyledPageNotFoundWrapper>
  )
}

export default PageNotFound
