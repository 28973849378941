import { useContext } from 'react'
import { PortableText } from '@portabletext/react'
import { useWindowSize } from 'sebn-library'
import { getImageUrl, getFileUrl } from 'sanityClient.js'

import { Context } from 'store'
import { HelmetSEO, ServicesIntro } from 'components'
import { ReactComponent as CheilConnecIcon } from 'assets/images/cheil-connec.svg'
import { Parallax } from 'react-scroll-parallax'
import {
  StyledAboutBackground,
  StyledAboutContainer,
  StyledTitle,
  StyledDescription,
  StyledTeamImageContainer,
  StyledTitleFullWidth,
  StyledPartnersDescription,
  StyledPartnersListContainer,
  StyledPartner,
  StyledPartnerLogoContainer,
  StyledPartnerText,
  StyledMoreInfoLink,
  StyledAwardsListContainer,
  StyledAwardsWrapper,
  StyledAwardsContainer,
  StyledAwardsDescription,
  StyledAward,
  StyledPartnerTextContainer,
  StyledIconContainer,
  StyledTopAboutContent,
  StyledBottomAboutContent
} from './About.styled'
import AboutClientsSection from './AboutClientsSection'

const helmetCopy = {
  title: 'About us, Connec+ network and awards| Cheil Benelux',
  description:
    'Our agency combines the on- and offline world to help you grow. Our  Connec+ network partners offer a vast range of expertise.',
  keywords:
    'what is cheil benelux, cheil network, netwerk, creative partners, connec+, cheil benelux clients, klanten, awards, prijzen, history, geschiedenis, about us, over ons'
}
const About = () => {
  const {
    state: { about }
  } = useContext(Context)
  const { width } = useWindowSize()

  const {
    title,
    description,
    awards_description,
    awards_list,
    awards_title,
    client_list,
    client_title,
    partners_description,
    partners_title,
    partners_list,
    team_description,
    team_photo,
    team_title,
    hero_image,
    hero_image_mobile
  } = about

  return (
    <>
      <HelmetSEO copy={helmetCopy} />
      {width < 900 && hero_image_mobile ? (
        <StyledAboutBackground
          bgimage={getFileUrl(hero_image_mobile.asset._ref)}
        />
      ) : (
        hero_image && (
          <StyledAboutBackground bgimage={getFileUrl(hero_image.asset._ref)} />
        )
      )}

      <StyledAboutContainer>
        <StyledTopAboutContent>
          <StyledTitle>{title}</StyledTitle>
          <PortableText
            value={description}
            components={{ block: { normal: StyledDescription } }}
          />
        </StyledTopAboutContent>
      </StyledAboutContainer>

      <StyledTeamImageContainer>
        <Parallax translateY={[-10, 10]} speed={-5}>
          <img src={getImageUrl(team_photo).url()} alt="Team" />
        </Parallax>
      </StyledTeamImageContainer>

      <StyledAboutContainer>
        <StyledBottomAboutContent>
          <StyledTitle>{team_title}</StyledTitle>
          <PortableText
            value={team_description}
            components={{ block: { normal: StyledDescription } }}
          />
        </StyledBottomAboutContent>
      </StyledAboutContainer>

      <AboutClientsSection data={{ client_list, client_title }} />

      <StyledAboutContainer>
        <StyledIconContainer whileHover={{ skew: -10, y: 0 }}>
          <CheilConnecIcon
            onClick={() =>
              window.open('https://www.cheilconnect.com/', '_blank')
            }
          />
        </StyledIconContainer>
        <StyledTitleFullWidth>{partners_title}</StyledTitleFullWidth>
        <StyledPartnerTextContainer>
          <PortableText
            value={partners_description}
            components={{
              block: { normal: StyledPartnersDescription }
            }}
          />
        </StyledPartnerTextContainer>
        <StyledPartnersListContainer>
          {partners_list.map(
            ({ _key, name, icon, partner_description, link }) => (
              <StyledPartner key={_key}>
                {icon && (
                  <StyledPartnerLogoContainer>
                    <img src={getImageUrl(icon.asset).url()} alt={name} />
                  </StyledPartnerLogoContainer>
                )}
                <PortableText
                  value={partner_description}
                  components={{ block: { normal: StyledPartnerText } }}
                />
                <StyledMoreInfoLink onClick={() => window.open(link, '_blank')}>
                  <StyledPartnerText>{'More info ->'}</StyledPartnerText>
                </StyledMoreInfoLink>
              </StyledPartner>
            )
          )}
        </StyledPartnersListContainer>
      </StyledAboutContainer>
      <ServicesIntro />

      <StyledAwardsWrapper>
        <h2>{awards_title}</h2>
        <PortableText
          value={awards_description}
          components={{ block: { normal: StyledAwardsDescription } }}
        />
        <StyledAwardsListContainer>
          {awards_list.map(({ award_amount, name, icon, _key }) => (
            <StyledAwardsContainer key={_key}>
              {icon && <img src={getImageUrl(icon.asset).url()} alt={name} />}
              <StyledAward>
                <div>{name}</div>
                {award_amount && <div>{award_amount}</div>}
              </StyledAward>
            </StyledAwardsContainer>
          ))}
        </StyledAwardsListContainer>
      </StyledAwardsWrapper>
    </>
  )
}

export default About
