import styled from 'styled-components'
import { colors } from 'assets/common/colors'
import { SubTitle2 } from 'assets/common/typography'
import { motion } from 'framer-motion'

const { white, gray, black111 } = colors

export const StyledMenuWrapper = styled(motion.div)`
  background-color: ${black111};
  height: 100vh;
  z-index: 1;
  width: 65%;
  color: ${white};
  text-align: left;
  letter-spacing: -0.03em;
  position: fixed;
  top: 0;

  @media (max-width: 1024px) {
    width: 100%;
  }
`

export const StyledMenuDarken = styled.div`
  background-color: ${({ open }) =>
    open ? 'rgba(0, 0, 0, .4)' : 'rgba(0, 0, 0, 0)'};
  height: 100vh;
  width: 100vw;
  z-index: -1;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
`

export const StyledMenuContainer = styled.div`
  margin-top: 12.5rem;
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  justify-content: center;
  text-transform: capitalize;
  position: relative;
  margin: 9rem 0 9rem 7rem;
  @media (max-width: 600px) {
    margin: 9rem 0 9rem 0rem;
  }
  @media (min-width: 601px) and (max-width: 1350px) {
    margin: 9rem 0 9rem 4rem;
    @media (max-height: 720px) and (max-width: 1024px) {
      margin: 9rem 0 0rem 0rem;
    }
  }
`
export const StyledContainer = styled.div`
  display: flex;
  margin-top: 4.375rem;
  @media (max-width: 768px) {
    margin-top: 0;
    flex-direction: column;
    padding: 20px 1.5625rem;
  }

  @media (max-height: 900px) {
    margin-top: 0;
  }

  @media (min-width: 600px) and (max-height: 720px) {
    flex-direction: row;
  }
`
export const StyledMainMenu = styled.div`
  @media (max-width: 600px) {
    margin-top: -2rem;
    margin-left: -1rem;
  }
`
export const StyledSubMenu = styled.div`
  margin-top: 2rem;
  margin-left: 9.375rem;
  width: 12.5rem;

  a {
    p {
      margin-top: 1rem;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
    }

    :hover {
      p {
        transform: translateX(0.5rem);
      }
    }
  }

  @media (min-width: 1024px) and (max-width: 1240px) {
    margin-left: 3rem;
  }

  @media (max-width: 768px) {
    margin-left: 1.25rem;
  }

  @media (max-width: 600px) {
    margin-left: -1rem;
    width: 0;
  }

  @media (max-height: 720px) and (max-width: 800px) {
    margin-top: 0.5rem;
    width: 0;
  }
`

export const StyledMainMenuItem = styled.h2`
  color: ${({ color }) => color};
  cursor: pointer;
  font-size: 4rem;
  margin: 1rem;

  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;

  :hover {
    transform: translateX(0.5rem);
  }

  @media (max-height: 720px) and (max-width: 800px) {
    font-size: 3rem;
    margin: 1rem 2.5rem;
  }

  @media (max-width: 420px) {
    font-size: 3rem;
    margin: 1rem 2.5rem;
  }

  @media (min-width: 420px) and (max-width: 600px) {
    font-size: 3.375rem;
    margin: 2rem 3.5rem;
  }

  @media (min-width: 1024px) {
    font-size: 4.375rem;
    margin: 1rem;
    line-height: 140%;
  }
`
export const StyledSubMenuItem = styled(SubTitle2)`
  cursor: pointer;
  margin-top: 2.9rem;

  @media (max-width: 600px) {
    margin: 2rem 3.5rem;
  }
  @media (max-width: 1024px) {
    font-size: 1.3513rem;
    margin-top: 2.5rem;
  }
`

export const StyledContactContainer = styled.div`
  margin-top: 4rem;
  margin-left: 1rem;
  @media (max-width: 600px) {
    display: none;
    visibility: hidden;
  }
`

export const StyleContactItem = styled.p`
  &.email {
    cursor: pointer;
  }

  font-weight: 400;
  font-size: 1.25rem;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: ${gray};
  text-transform: none;
`
