import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { HashLink } from '@xzar90/react-router-hash-link'
import { Context } from 'store'
import { MAIN_MENU, SUB_MENU } from 'assets/common/constants'
import { showContactForm } from 'store/actions'
import { colors } from 'assets/common/colors'
import { createPortal } from 'react-dom'
import {
  StyledMenuWrapper,
  StyledMainMenu,
  StyledSubMenu,
  StyledMenuContainer,
  StyledContainer,
  StyledMainMenuItem,
  StyledSubMenuItem,
  StyleContactItem,
  StyledContactContainer,
  StyledMenuDarken
} from './Menu.styled'
import { useMediaQuery } from '../../hooks/useMediaQuery'

const { mint, yellow, magenta, green } = colors

const menuColors = [mint, yellow, magenta, green]

const Menu = ({ open, setOpen }) => {
  const { dispatch } = useContext(Context)
  const links = (item) => {
    let link = ''

    if (item === 'vacancies') {
      link = '/people#vacancy'
    } else if (item === 'home') {
      link = '/'
    } else {
      link = `/${item}`
    }

    return link
  }

  // TODO: create xs, sm, md, lg, xl and 2xl standard sizes for media queries
  const handleMenuClick = () => setOpen(!open)
  const isLargeScreen = useMediaQuery('1024px')

  const wrapperVariants = {
    hidden: {
      right: '-110%'
    },
    visible: {
      right: isLargeScreen ? '-10%' : 0,
      transition: {
        type: 'spring',
        stiffness: 80,
        duration: 0.3,
        damping: isLargeScreen ? 12 : 20
      }
    },
    exit: {
      right: '-110%'
    }
  }

  const logoContainer = document.getElementById('cheil-logo')

  return (
    <>
      {logoContainer &&
        createPortal(<StyledMenuDarken open={open} />, logoContainer)}
      <StyledMenuWrapper
        variants={wrapperVariants}
        initial="hidden"
        animate={open ? 'visible' : 'hidden'}
        exit="exit"
      >
        <StyledMenuContainer>
          <StyledContainer>
            <StyledMainMenu>
              {MAIN_MENU.map((item, index) => (
                <HashLink to={links(item)} key={item}>
                  <StyledMainMenuItem
                    onClick={handleMenuClick}
                    color={menuColors[index]}
                  >
                    {item}
                  </StyledMainMenuItem>
                </HashLink>
              ))}
            </StyledMainMenu>
            <StyledSubMenu>
              {SUB_MENU.map((item) => (
                <HashLink to={links(item)} key={item}>
                  <StyledSubMenuItem onClick={handleMenuClick}>
                    {item}
                  </StyledSubMenuItem>
                </HashLink>
              ))}
            </StyledSubMenu>
          </StyledContainer>
          <StyledContactContainer>
            <StyleContactItem>+31 (0)20 217 03 60</StyleContactItem>
            <StyleContactItem
              className="email"
              onClick={() => dispatch(showContactForm(true))}
            >
              contact@cheil.com
            </StyleContactItem>
          </StyledContactContainer>
        </StyledMenuContainer>
      </StyledMenuWrapper>
    </>
  )
}

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

export default Menu
