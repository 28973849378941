import { useContext, useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Context } from 'store'
import { ContactForm, Footer, Navigation, Vacancy, WorkEntry } from 'components'

import {
  PageNotFound,
  Services,
  People,
  News,
  NewsDetails,
  // Privacy,
  Legal,
  Homepage,
  About,
  Work,
  Contact
} from 'pages'
import fetchData from 'api/fetchData'
import { changeLoadingState, setData, setGeoData } from 'store/actions'
import { StyledApp, StyledLoaderContainer } from 'App.styled'
import { AnimatePresence } from 'framer-motion'
import fetchGeoData from './api/fetchGeoData'
import Loader from './components/Loader/Loader'
import { Redirect } from './Redirect'

const App = () => {
  const {
    dispatch,
    state: { isLoading, showForm }
  } = useContext(Context)
  const location = useLocation()

  useEffect(() => {
    const fetchContent = async () => {
      const data = await fetchData()
      if (!data) return

      dispatch(setData(data))
      dispatch(changeLoadingState(false))
      fetchGeoData().then((result) => {
        if (result.status === 200) {
          dispatch(setGeoData(result.data))
        }
      })
    }

    fetchContent()
  }, [dispatch])

  return (
    <AnimatePresence>
      {isLoading ? (
        <StyledLoaderContainer key="preloader">
          <Loader isInverted />
        </StyledLoaderContainer>
      ) : (
        <StyledApp key="application">
          <Navigation />
          {showForm && <ContactForm />}
          <TransitionGroup>
            <CSSTransition
              key={location.pathname}
              timeout={350}
              classNames="fade"
            >
              <Routes>
                <Route path="" element={<Homepage />} />
                <Route path="about" element={<About />} />
                <Route path="work" element={<Work />} />
                {/* 5 redirects for ID based work entries, should be deleted in the future but are currently in use and should temporarily be redirected */}
                <Route
                  path="work/d05878c7-89b9-42f1-8730-999a83e17237/samsung/a-playful-product-experience"
                  element={
                    <Redirect url="../work/samsung/a-playful-product-experience" />
                  }
                />
                <Route
                  path="work/b5209dc5-12c6-4a53-be57-768d8a037c3a/samsung/samsung-experience-stores"
                  element={
                    <Redirect url="../work/samsung/samsung-experience-stores" />
                  }
                />
                <Route
                  path="work/dddd957f-9267-4a18-acac-74974bed17bc/ministerie-van-infrastructuur-en-waterstaat/the-lottery-you-dont-want-to-win"
                  element={
                    <Redirect url="../work/ministerie-van-infrastructuur-en-waterstaat/the-lottery-you-dont-want-to-win" />
                  }
                />
                <Route
                  path="work/cd7d2c29-c20e-4356-9835-b0bde30b41b0/samsung/the-sponsorship-that-gave-a-head-start"
                  element={
                    <Redirect url="../work/samsung/the-sponsorship-that-gave-a-head-start" />
                  }
                />
                <Route
                  path="work/3a4f4829-6989-4729-9855-7b1c43c807a1/samsung/a-campaign-for-and-by-gen-z"
                  element={
                    <Redirect url="../work/samsung/a-campaign-for-and-by-gen-z" />
                  }
                />
                <Route path="work/:client/:title" element={<WorkEntry />} />
                <Route path="services" element={<Services />} />
                <Route path="people" element={<People />} />
                <Route
                  path="people/vacancy/:id/:function/:location"
                  element={<Vacancy />}
                />
                <Route
                  path="people/vacancy/:id/:function"
                  element={<Vacancy />}
                />
                <Route path="updates" element={<News />} />
                <Route path="updates/:key" element={<NewsDetails />} />
                <Route path="contact" element={<Contact />} />
                {/* <Route path="privacy" element={<Privacy />} /> */}
                <Route path="legal" element={<Legal />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </CSSTransition>
          </TransitionGroup>
          <Footer />
        </StyledApp>
      )}
    </AnimatePresence>
  )
}

export default App
