import styled from 'styled-components'

export const StyledCenteredContainer = styled.div`
  max-width: 1512px;
  width: 100%;
  margin: 0 auto;
  padding: 5rem;

  @media (max-width: 900px) {
    padding: 5rem 3.75rem;
  }
  @media (max-width: 500px) {
    padding: 5rem 2rem;
  }
`
