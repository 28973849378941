import styled, { css } from 'styled-components'
import { StyledCenteredContainer } from 'assets/common/mixin'

export const StyledMediaBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  margin: 5rem 0;

  @media (max-width: 900px) {
    margin: 0;
    gap: 2rem;
  }
  /* video {
    width: 100%;
    height: auto;
  } */

  ${({ amount }) => {
    if (amount === 2) {
      return css`
        /* video {
          min-width: 40rem;
          height: 100%;
        }

        @media (max-width: 500px) {
          img {
            min-width: unset;
          }
          flex-direction: column;
        } */
      `
    }
    return null
  }}

  ${({ amount }) => {
    if (amount === 3) {
      return css`
        /* display: flex;
        justify-content: space-between;
        /* background: red; */

        /* @media (max-width: 500px) {
          flex-direction: column;
        } */
      `
    }
    return null
  }}

  ${({ videoStyle }) => {
    if (videoStyle === 'full-width') {
      return css`
        display: block;
        ${StyledCenteredContainer}
        margin-left: -9.3%;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        max-width: 100vw;
        width: 100vw;
        /* margin-top: -3rem;
        margin-bottom: -3rem; */
      `
    }
    return null
  }}
`

export const StyledVideoContainer = styled.video`
  width: 100%;
  height: auto;

  @media (max-width: 900px) {
    margin: 1rem 0 1rem;
  }
`
