import styled from 'styled-components'
import { StyledCenteredContainer } from 'assets/common/mixin'

export const StyledContactBlock = styled(StyledCenteredContainer)`
  display: flex;
  align-items: center;
  padding-top: unset;
  padding-bottom: unset;
`

export const StyledDescriptionContainer = styled.div`
  flex-basis: 50%;
  flex-shrink: 0;
`

export const StyledDescription = styled.h3`
  width: 65%;

  @media (min-width: 1000px) {
    font-size: 2.03175rem;
  }
`

export const StyledContactDetails = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
  background: ${({ bgcolor }) => bgcolor};
  margin-right: -50vw;
  width: 100vw;

  img {
    width: 100%;
    height: 100%;
    margin-bottom: -0.3rem;
  }
`

export const StyledContactImage = styled.div`
  flex: 0 1 338px;
  max-width: 338px;

  @media (min-width: 900px) {
    flex: 0 1 262.772px;
    max-width: 262.772px;
  }
`

export const StyledContactText = styled.div`
  h6 {
    font-family: 'Inter';
    margin-bottom: 0.5rem;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.0325rem;

    &.italic {
      font-style: italic;
    }
  }
`

export const StyledTextEmojiContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-top: 0;
  cursor: pointer;
  margin-top: 0.5rem;

  @media (max-width: 1024px) {
    margin-top: 0;
  }
`

export const StyledContactNote = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.6;
  font-size: 0.8125rem;
  margin-left: -1rem;
  margin-top: -0.5rem;

  svg {
    width: 3rem;
    height: 3rem;
  }
`
