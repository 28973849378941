import React from 'react'
import PropTypes from 'prop-types'
import { ImageBlock } from 'components'
import { SubTitle1 } from 'assets/common/typography'
import { PortableText } from '@portabletext/react'
import {
  StyledTextBlockContainer,
  StyledColumnsContainer,
  StyledColumnContainer,
  StyledTextBlockTitle,
  StyledListItem
} from './TextBlock.styled'

const columns = ['col1', 'col2', 'col3']

const TextBlock = ({ value }) => {
  const missingColumn = columns.find(
    (column) => typeof value[column] === 'undefined'
  )
  return (
    <StyledTextBlockContainer>
      {value.title && (
        <StyledTextBlockTitle>{value.title}</StyledTextBlockTitle>
      )}
      <StyledColumnsContainer missingColumn={missingColumn}>
        {columns.map((column) => {
          return typeof value[column] !== 'undefined' ? (
            <StyledColumnContainer
              key={column}
              isFullWidthColumn={missingColumn === 'col2'}
            >
              <PortableText
                key={column}
                value={value[column]}
                components={{
                  block: {
                    normal: SubTitle1
                  },
                  listItem: {
                    bullet: StyledListItem
                  },
                  types: {
                    image: ImageBlock
                  }
                }}
              />
            </StyledColumnContainer>
          ) : null
        })}
      </StyledColumnsContainer>
    </StyledTextBlockContainer>
  )
}

TextBlock.propTypes = {
  value: PropTypes.shape({
    title: PropTypes.string
  }).isRequired
}

export default TextBlock
