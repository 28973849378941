import styled, { css } from 'styled-components'

export const StyledVideoBlockContainer = styled.div`
  position: relative;
  z-index: 2;

  img {
    width: 100%;
    height: auto;
  }

  ${({ isThumbnail }) =>
    isThumbnail &&
    css`
      width: 26.25rem;
      height: 26.25rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media (max-width: 900px) {
        width: 23.75rem;
        height: 23.75rem;
      }

      @media (max-width: 400px) {
        width: unset;
        height: unset;
      }
    `}/* ${({ isNewsDetails }) =>
    isNewsDetails &&
    css`
      min-width: 67.5rem;
      min-height: 32.5rem;
      margin: 0 auto;

      img,
      video {
        width: 100%;
        height: auto;
      }

      video {
        object-fit: contain;
      }

      img {
        object-fit: cover;
      }

      @media (max-width: 900px) {
        width: 26.25rem;
        height: 26.25rem;
      }
    `} */
`

export const StyledPlayCircle = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  svg {
    cursor: pointer;
  }

  @media (max-width: 575px) {
    svg {
      width: 5rem;
      height: 5rem;
    }
  }
`

// export const StyledMediaContainer = styled.div`
//   width: 624px;
//   height: 100%;

//   @media (max-width: 900px) {
//     margin: 1rem 0 1rem;
//     width: unset;
//   }
// `
export const StyledVideoContainer = styled.video`
  width: 100%;
  height: auto;

  @media (max-width: 900px) {
    margin: 1rem 0 1rem;
  }
`

// export const StyledImgContainer = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;

//   @media (max-width: 900px) {
//     margin: 1rem 0 1rem;
//     width: 100%;
//   }
// `
