import styled from 'styled-components'
import { StyledCenteredContainer } from 'assets/common/mixin'

export const StyledLegalWrapper = styled(StyledCenteredContainer)`
  padding-top: 18.625rem;
  padding-bottom: 10rem;

  @media (max-width: 900px) {
    padding-top: 12.5rem;
    padding-bottom: 2.5rem;
  }
`

export const StyledListItem = styled.li`
  font-size: 1.125rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.0225rem;
`
