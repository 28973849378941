import PropTypes from 'prop-types'
import { Body1 } from 'assets/common/typography'
import { StyledArticleInfo } from './ArticleInfo.styled'

const ArticleInfo = ({ children }) => {
  return (
    <StyledArticleInfo>
      <Body1>{children}</Body1>
    </StyledArticleInfo>
  )
}

ArticleInfo.propTypes = {
  children: PropTypes.node.isRequired
}

export default ArticleInfo
