export const colors = {
  black: 'rgba(20, 20, 20, 0.9)',
  black111: '#111',
  white: '#fff',
  gray: '#777777',
  gray2: '#e8e8e8',
  gray3: '#555555',
  lightGray: '#f9f9f9',
  mint: '#01c8ef',
  yellow: '#F3B243',
  pineapple: '#F2B01D',
  magenta: '#FF557D',
  pink: '#EF567C',
  green: '#94C97B',
  purple: '#A264D7'
}
