import React from 'react'
import PropTypes from 'prop-types'
import { PortableText } from '@portabletext/react'
import { SubTitle1 } from 'assets/common/typography'
import {
  StyledTitleAndTextBlockContainer,
  StyledTitleBlock,
  StyledTextBlock,
  StyledListItem,
  StyledLink
} from './TitleAndTextBlock.styled'

function isValidURL(url) {
  try {
    // eslint-disable-next-line no-new
    new URL(url)
    return true // URL is valid
  } catch (error) {
    return false // URL is not valid
  }
}

const TitleAndTextBlock = ({
  value: { title, description, title_position }
}) => {
  return (
    <div>
      <StyledTitleAndTextBlockContainer position={title_position}>
        <StyledTitleBlock position={title_position}>
          <h2>{title}</h2>
        </StyledTitleBlock>
        <StyledTextBlock position={title_position}>
          <PortableText
            value={description}
            components={{
              block: {
                normal: SubTitle1
              },
              listItem: {
                bullet: StyledListItem
              },
              marks: {
                // eslint-disable-next-line react/no-unstable-nested-components
                link: ({ value, children }) => {
                  const { href } = value
                  if (href && (href.startsWith('http') || isValidURL(href))) {
                    return (
                      <StyledLink
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {children}
                      </StyledLink>
                    )
                  }
                  return children
                }
              }
            }}
          />
        </StyledTextBlock>
      </StyledTitleAndTextBlockContainer>
    </div>
  )
}

TitleAndTextBlock.propTypes = {
  value: PropTypes.shape({
    title: PropTypes.string,
    title_position: PropTypes.string.isRequired,
    description: PropTypes.arrayOf(
      PropTypes.shape({
        _key: PropTypes.string.isRequired,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            _key: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired
          })
        )
      })
    )
  }).isRequired
}

export default TitleAndTextBlock
