import styled from 'styled-components'
import { StyledCenteredContainer } from 'assets/common/mixin'

// The swiper package automatically creates a 'swiper > swiper-wrapper > swiper-slide' dom structure from the Swiper component
export const StyledSwiperContainer = styled(StyledCenteredContainer)`
  // this makes sure the images move smoothly, the swiper component renderns a
  .swiper-wrapper {
    transition-timing-function: linear !important;
  }

  .swiper-slide {
    width: fit-content;
    align-self: center;
  }
`
