import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

// redirect hack usable by loading component on Route load
export const Redirect = ({ url }) => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(url, { replace: true })

    // eslint-disable-next-line
  }, [])

  return null
}

Redirect.propTypes = {
  url: PropTypes.string.isRequired
}
