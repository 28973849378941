import styled from 'styled-components'

export const SubTitle1 = styled.h6`
  /* font-size: 26px; */
  font-family: 'Inter', Arial, Sans-Serif;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.0325rem;

  @media (max-width: 900px) {
    font-size: 1.125rem;
    letter-spacing: -0.0225rem;
  }
`

export const SubTitle2 = styled.p`
  font-size: 1.375rem; // 22px
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
`

export const Subtitle3 = styled.p`
  font-size: 1.25rem; // 20px
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.36px;
`

export const Subtitle4 = styled.p`
  font-size: 1.1875rem; // 19px
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.36px;

  @media (min-width: 1024px) {
    width: 40%;
  }
`

export const Body1 = styled.p`
  font-family: 'Inter', Arial, Sans-Serif;
  font-size: 1.125rem; // 18px
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.0225rem;
`

export const Body2 = styled.p`
  font-size: 1.071rem; // 17px
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 1.96875rem */
  letter-spacing: -0.0225rem;

  @media (max-width: 900px) {
    font-size: 0.9375rem;
    letter-spacing: -0.01875rem;
  }
`

export const Body3 = styled.p`
  font-family: 'Inter', Arial, Sans-Serif;
  font-size: 0.9375rem; // 15px
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.3px;
`

export const Body4 = styled.p`
  font-family: 'Inter', Arial, Sans-Serif;
  font-size: 0.875rem; // 14px
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`
