import styled, { css } from 'styled-components'
import { Body1 } from 'assets/common/typography'

export const StyledTextBlockContainer = styled.div`
  margin: 2rem 0;
`

export const StyledTextBlockTitle = styled(Body1)`
  color: #777777;
`

export const StyledColumnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`

export const StyledColumnContainer = styled.div`
  width: 50%;

  ${({ isFullWidthColumn }) => {
    if (isFullWidthColumn) {
      return css`
        width: 100%;
      `
    }
    return null
  }}

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`

export const StyledListItem = styled.li`
  font-size: 1.125rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.0225rem;
`
