import styled from 'styled-components'
import { colors } from 'assets/common/colors'
import { motion } from 'framer-motion'

const { white, black } = colors

const StyledBasicButton = styled.button`
  border-radius: 6.25rem;
  padding: 1rem 2rem;
  cursor: pointer;
  min-height: 3.125rem;
`

export const StyledButton = styled(StyledBasicButton)`
  border: 1px solid ${white};
  background: ${({ darkBg }) => (darkBg ? white : 'transparent')};
  color: ${({ whiteBg }) => (whiteBg ? black : white)};
  position: relative;
  width: auto;
  &:hover {
    border: 1px solid transparent;
    color: ${({ darkBg }) => (darkBg ? white : black)};
  }

  // framer
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  /* @media (prefers-color-scheme: dark) {
    filter: invert(1);
    color: ${({ whiteBg }) => (whiteBg ? 'blue' : white)};
  } */
`

export const StyledRound = styled(motion.div)`
  width: 1px;
  position: absolute;
  opacity: 0;
  aspect-ratio: 1 / 1;
  border-radius: 100%;
`
export const StyledGradientButton = styled(StyledBasicButton)`
  background: ${({ darkBg }) => (darkBg ? white : 'transparent')};
  color: ${({ whiteBg }) => (whiteBg ? black : white)};
  position: relative;
  border: none;
  width: auto;

  &:hover {
    border: none;
  }

  &:disabled {
    pointer-events: none;
    background: lightgray;

    &:before {
      background: gray;
    }
  }

  // framer
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 6.25rem;
    padding: 0.0625rem;
    background: linear-gradient(
      83.1deg,
      #5ab5ea 8.18%,
      #a264d7 37.76%,
      #f3b243 62.4%,
      #ff557d 98.32%
    );
    -webkit-mask:
      linear-gradient(${white} 0 0) content-box,
      linear-gradient(${white} 0 0);
    mask:
      linear-gradient(${white} 0 0) content-box,
      linear-gradient(${white} 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`
