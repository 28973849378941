import styled from 'styled-components'
import { colors } from 'assets/common/colors'

const { white, black111 } = colors

export const StyledLoaderContainer = styled.main`
  background: ${black111};
  height: 100vh;
  width: 100vw;
  color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledApp = styled.main`
  margin: 0 auto;
  background: ${black111};
  color: ${white};
  z-index: 0;
  position: relative;
`
