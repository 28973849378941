import styled from 'styled-components'
import { colors } from 'assets/common/colors'
import { StyledCenteredContainer } from 'assets/common/mixin'
import { Body1 } from 'assets/common/typography'

const { white, black111 } = colors

export const StyledHeroContainer = styled(StyledCenteredContainer)`
  padding-top: 18rem;
  user-select: none;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  @media (min-width: 1200px) {
    canvas {
      left: unset;
      right: 0;
    }
  }

  // canvas "top" props might be overwritten by WebGL renderer
  @media (max-width: 900px) {
    padding-top: 13rem;
    min-height: 60rem;

    @media (max-width: 500px) {
      min-height: 50rem;
    }

    canvas {
      top: 25rem;
    }
  }

  @media (max-width: 500px) {
    canvas {
      top: 35rem;
    }
  }
`

export const StyledTextContainer = styled.div`
  width: 42%;

  @media (max-width: 900px) {
    width: 100%;
  }
`

export const StyledHeroTitle = styled.h2`
  width: 80%;
`

export const StyledServicesDetailsBackground = styled.div`
  background: ${white};
  color: ${black111};
`

export const StyledDetailsContainer = styled(StyledCenteredContainer)`
  @media (max-width: 900px) {
    width: 100%;
  }
`

export const StyledTitle = styled.h2`
  margin-top: -0.3125rem;
`

export const StyledContentDescription = styled(Body1)`
  width: 50%;

  @media (max-width: 900px) {
    width: 100%;
  }
`

export const StyledServicesItem = styled.div`
  display: flex;
  padding: 0 3.75rem 0 0.625rem;
  gap: 1.25rem;
  background-color: ${(props) => props.color};
  align-items: center;
  justify-content: space-between;
  position: relative;

  &:first-child {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${white};
      clip-path: polygon(93% 0, 96% 0, 94.5% 18%);
    }
  }
`
export const StyledService = styled.h4`
  padding: 0 6.25rem;
  color: ${black111};
`
