export const removeWhitespace = (str) => str.replace(/\s+/g, '')

export const scrollToSection = (elementId) => {
  const element = document.getElementById(elementId)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

export const formatStringForPath = (str) => {
  return str
    .trim()
    .replace(/[^\w\s-]/gi, '')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

export const generatePath = (...params) => {
  const words = params.map((param) => formatStringForPath(param))
  const path = words.join('/')
  return path
}

export const setWorkEntryPath = ({ client, title }) => {
  if (!client || !title) return null
  return generatePath(client, title)
}
