import React, { useContext, useState } from 'react'
import { Button } from 'components'

import sendEmail from 'api/sendEmail'
import { Context } from 'store'
import { showContactForm, setContactPerson } from 'store/actions'
import { Body1 } from 'assets/common/typography'
import { ReactComponent as CloseIcon } from 'assets/images/close_icon.svg'
import {
  StyledContactFormContainer,
  StyledContactFormWrapper,
  StyledFormFields,
  StyledRainbowOutline,
  StyledCloseBtn,
  StyledConfirmPopupTitle,
  StyledLoaderContainer,
  StyledDisabledButtonWrapper
} from './ContactForm.styled'
import Loader from '../Loader/Loader'

const ContactForm = () => {
  const {
    dispatch,
    state: { contactPerson }
  } = useContext(Context)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [confirmationOpen, setConfirmationOpen] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const defaultEmail = 'contact@cheil.com'
    const emailTo = contactPerson.contact_email || defaultEmail
    setIsLoading(true)
    await sendEmail({
      emailTo,
      name,
      email,
      subject,
      message,
      contact_name: contactPerson.name || 'Contact'
    })
    setIsLoading(false)
    setConfirmationOpen(true)
  }

  const handleModalClose = () => {
    dispatch(showContactForm(false))
    setConfirmationOpen(false)
    dispatch(
      setContactPerson({
        name: '',
        email: 'contact@cheil.com'
      })
    )
  }

  return (
    <StyledContactFormWrapper>
      <StyledContactFormContainer>
        <StyledCloseBtn onClick={handleModalClose}>
          <CloseIcon />
        </StyledCloseBtn>
        {!confirmationOpen ? (
          <>
            <h4>
              {contactPerson.contact_name
                ? `Write to ${contactPerson.contact_name}`
                : 'Drop us a note'}
            </h4>
            {!isLoading && (
              <Body1>
                {contactPerson.contact_email
                  ? `Do you have a question or want to discuss a project together? We’d love to hear from you. If you’d prefer to email us instead, reach out to ${contactPerson.contact_email} or at contact@cheil.com`
                  : `For instance about a new project, event, or press inquiry. We’d love to hear from you. If you’d prefer to email us instead, reach out to us at contact@cheil.com`}
              </Body1>
            )}
            <StyledFormFields onSubmit={handleSubmit}>
              {isLoading ? (
                <StyledLoaderContainer>
                  <Loader />
                </StyledLoaderContainer>
              ) : (
                <>
                  <label htmlFor="name">
                    What&apos;s your name?
                    <StyledRainbowOutline>
                      <input
                        onChange={(e) => setName(e.target.value)}
                        required
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Your name"
                        value={name}
                      />
                    </StyledRainbowOutline>
                  </label>

                  <label htmlFor="email">
                    And your email address?
                    <StyledRainbowOutline>
                      <input
                        type="email"
                        id="email"
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        placeholder="Your email"
                        required
                        size="30"
                        value={email}
                      />
                    </StyledRainbowOutline>
                  </label>

                  <label htmlFor="subject">
                    What is it about?
                    <StyledRainbowOutline>
                      <input
                        value={subject}
                        type="text"
                        name="subject"
                        onChange={(e) => setSubject(e.target.value)}
                        required
                        placeholder="Subject"
                      />
                    </StyledRainbowOutline>
                  </label>

                  <label htmlFor="message">
                    What is your message?
                    <StyledRainbowOutline>
                      <textarea
                        value={message}
                        minLength={30}
                        name="message"
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        placeholder="Tell us how we can help you..."
                      />
                    </StyledRainbowOutline>
                  </label>
                </>
              )}
              {isLoading ? (
                <StyledDisabledButtonWrapper>
                  <Button
                    whiteBg
                    isGradient
                    isDisabled={isLoading}
                    textButton="Sending..."
                    isNoArrow
                  />
                </StyledDisabledButtonWrapper>
              ) : (
                <Button
                  whiteBg
                  type="submit"
                  isGradient
                  textButton="Send message"
                />
              )}
            </StyledFormFields>
          </>
        ) : (
          <>
            <StyledConfirmPopupTitle>
              Thank you for reaching out
            </StyledConfirmPopupTitle>
            <Body1>
              We want to confirm that your message has been successfully sent. A
              copy has been sent to your mailbox. We appreciate you taking the
              time to contact us and value your interest. We will do our best to
              address your needs in a timely manner.
            </Body1>
          </>
        )}
      </StyledContactFormContainer>
    </StyledContactFormWrapper>
  )
}

export default ContactForm
