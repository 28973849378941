import styled, { css } from 'styled-components'
import { Body3 } from 'assets/common/typography'
// import { colors } from 'assets/common/colors'

// const { white, black111 } = colors

export const StyledContactPersonContainer = styled.div`
  display: flex;
  gap: 1rem;

  img {
    width: 6.25rem;
    height: 6.25rem;
  }

  ${({ isWorkEntry }) => {
    if (isWorkEntry) {
      return css`
        @media (max-width: 1000px) {
          padding: 0 3.75rem 2rem;
        }
        @media (max-width: 500px) {
          padding: 0 2rem 2rem;
        }
      `
    }
    return null
  }}
`

export const StyledContactDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledContactDetails = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledContactText = styled(Body3)`
  margin: 0.1rem;

  ${({ isServiceCard }) => {
    if (isServiceCard) {
      return css`
        font-style: italic;
        font-weight: 400;
      `
    }
    return null
  }}

  ${({ bold }) => {
    if (bold) {
      return css`
        font-weight: 700;
      `
    }
    return null
  }}
`

export const StyledContactNote = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.6;
  font-size: 0.8125rem;
  margin-left: -1rem;

  svg {
    width: 3rem;
    height: 3rem;
  }
`

export const StyledTextEmojiContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-top: -0.5rem;
  cursor: pointer;

  @media (max-width: 1024px) {
    margin-top: 0;
  }

  ${({ isWorkEntry }) => {
    if (isWorkEntry) {
      return css`
        color: #01c8ef;
      `
    }
    return null
  }}
`
