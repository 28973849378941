import axios from 'axios'

// Old on, no commercial use allowed
// const fetchGeoData = async () => {
//   const ipRequest = await axios.get('https://api.ipify.org?format=json')
//   if (ipRequest.status === 200) {
//     const geoRequest = await axios.get(
//       `http://ip-api.com/json/${ipRequest.data.ip}`
//     )
//     return geoRequest
//   }
//   return ipRequest
// }

const fetchGeoData = async () => {
  const ipRequest = await axios.get('https://api.ipify.org?format=json')
  if (ipRequest.status === 200) {
    const geoRequest = await axios.get(
      `https://ipinfo.io/${ipRequest.data.ip}?token=eba12d6669f00e`
    )
    return geoRequest
  }
  return ipRequest
}

export default fetchGeoData
