import imageUrlBuilder from '@sanity/image-url'

const PROJECT_ID = '8teahrr3'
const DATASEST = 'production'

const builder = imageUrlBuilder({
  projectId: PROJECT_ID,
  dataset: DATASEST
})

export const getImageUrl = (source) => {
  return builder.image(source)
}

export const getFileUrl = (ref) => {
  // Example ref: file-207fd9951e759130053d37cf0a558ffe84ddd1c9-mp3
  // https://cdn.sanity.io/files/8teahrr3/production/28da909744b89790048ccdd4e9da7422bc161b8f.jpg
  const [file, id, extension] = ref.split('-')
  return `https://cdn.sanity.io/${file}s/${PROJECT_ID}/${DATASEST}/${id}.${extension}`
}
