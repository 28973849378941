import styled from 'styled-components'
import { Body1 } from 'assets/common/typography'
import { StyledCenteredContainer } from 'assets/common/mixin'

export const StyledArticleListWrapper = styled(StyledCenteredContainer)`
  @media (min-width: 320px) {
    padding: 0;
  }
`

export const StyledArticleList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: baseline;
  margin-left: 0;
  padding-left: 0;
  cursor: pointer;
  gap: 0.2rem;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`

export const StyledArticleCard = styled.li`
  flex-basis: 33%;
  list-style: none;

  img {
    height: auto;
    max-width: 26.25rem;
    width: 100%;
  }

  @media (max-width: 700px) {
    padding: 0;
  }
`

export const StyledArticleTitle = styled(Body1)``

export const StyledHeroImageContainer = styled.div`
  margin-top: 4rem;
  position: relative;
`
