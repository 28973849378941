import styled from 'styled-components'
import { colors } from 'assets/common/colors'
import { StyledCenteredContainer } from 'assets/common/mixin'
import { Body1 } from 'assets/common/typography'

const { white } = colors

export const StyledContactWrapper = styled(StyledCenteredContainer)`
  padding-top: 18rem;
  color: ${white};

  button {
    margin-top: 3rem;
  }

  @media (max-width: 900px) {
    padding-top: 13rem;
  }
`

export const StyledContactContainer = styled.div`
  max-width: 45rem;

  .email {
    cursor: pointer;
  }
`

export const StyledContactDescription = styled(Body1)``

export const StyledLocationsContainer = styled.div`
  margin-top: 10rem;

  img {
    width: 100%;
    height: auto;
  }
`

export const StyledLocationsList = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 650px) {
    flex-direction: column;
    gap: 3rem;
  }
`

export const StyledLocation = styled.div`
  flex-basis: 33.33%;
  padding: 0.5rem;
`

export const StyledLocationContent = styled.p`
  margin: 0;
`
