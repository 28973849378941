import styled from 'styled-components'
import { colors } from 'assets/common/colors'

const { white, gray2, mint } = colors

export const StyledContactFormWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;

  @media (max-width: 900px) {
    padding: 0;
  }
`

export const StyledLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6rem 0;
`

export const StyledDisabledButtonWrapper = styled.div`
  cursor: not-allowed;
  border-radius: 999px;
  width: fit-content;
`

export const StyledContactFormContainer = styled.div`
  background-color: ${white};
  width: 90%;
  max-width: 43.75rem;
  padding: 3.125rem;
  color: black;
  position: relative;
  max-height: 90vh;
  overflow: auto;

  @media (max-width: 900px) {
    padding: 1.25rem;
  }
`

export const StyledFormFields = styled.form`
  gap: 1.875rem;
  font-family: 'Inter';
  margin-bottom: 1.25rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 175%;
  letter-spacing: -0.02em;
  label {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
  }

  input,
  textarea {
    background: transparent;
    padding: 14px 0.9375rem;
    border: none;
    position: relative;

    &:focus,
    &:active {
      outline: none;
    }
  }

  textarea {
    min-height: 6.25rem;
    width: 100%;
  }

  button {
    width: max-content;
  }
`

export const StyledRainbowOutline = styled.div`
  display: block;
  position: relative;
  border-radius: 0.3125rem;
  background: ${gray2};
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 0.3125rem;
    padding: 0.0625rem;
    mask:
      linear-gradient(${white} 0 0) content-box,
      linear-gradient(${white} 0 0);
    mask-composite: xor;
    mask-composite: exclude;
    background: linear-gradient(
      83.1deg,
      #5ab5ea 8.18%,
      #a264d7 37.76%,
      #f3b243 62.4%,
      #ff557d 98.32%
    );
    opacity: 0;
    transition: all 0.5s;
  }
  &:focus,
  &:active,
  &:focus-within {
    &:before {
      opacity: 1;
    }
  }
`

export const StyledCloseBtn = styled.div`
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  svg {
    cursor: pointer;
  }
`

export const StyledConfirmPopupTitle = styled.h4`
  color: ${mint};
`
