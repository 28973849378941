import { useContext } from 'react'
import moment from 'moment'
import { PortableText } from '@portabletext/react'
import { Context } from 'store'
import { HelmetSEO } from 'components'
import { Body1 } from 'assets/common/typography'
import { StyledLegalWrapper, StyledListItem } from './Legal.styled'

const helmetCopy = {
  title: 'Terms and Conditions | Cheil Benelux',
  description:
    'Cheil Benelux B.V. works with the following General Purchase Conditions.',
  keywords:
    'cheil terms and conditions, general purchase conditions, applicability, delivery of goods and provision of services, intellectual property rights, guarantee, liability, confidentiality, termination, applicable law, voorwaarden'
}

const Legal = () => {
  const {
    state: { terms }
  } = useContext(Context)
  const { title, updated, terms_description } = terms

  return (
    <StyledLegalWrapper>
      <HelmetSEO copy={helmetCopy} />
      <h2>{title}</h2>
      <Body1>Last updated: {moment(updated).format('D MMM YYYY')}</Body1>
      <PortableText
        value={terms_description}
        components={{
          block: { normal: Body1 },
          listItem: {
            bullet: StyledListItem
          }
        }}
      />
    </StyledLegalWrapper>
  )
}

export default Legal
