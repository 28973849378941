import React from 'react'
import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'
import { getFileUrl } from 'sanityClient.js'
import { VideoBlock } from 'components'
import { formatStringForPath } from 'assets/common/helper'
import {
  StyledArticleListWrapper,
  StyledArticleList,
  StyledArticleCard,
  StyledArticleTitle,
  StyledHeroImageContainer
} from './ArticleList.styled'

const ArticleList = ({ articles }) => {
  const navigate = useNavigate()

  const handleClick = (key) => {
    navigate(`/updates/${formatStringForPath(key)}`)
  }
  return (
    <StyledArticleListWrapper>
      <StyledArticleList>
        {articles.map(({ _key, hero_media, title: articleTitle }) => {
          return (
            <StyledArticleCard key={_key} onClick={() => handleClick(_key)}>
              {hero_media && (
                <StyledHeroImageContainer>
                  <VideoBlock
                    srcAsset={getFileUrl(hero_media.asset._ref)}
                    title={articleTitle}
                    isThumbnail
                  />
                </StyledHeroImageContainer>
              )}
              <StyledArticleTitle>{articleTitle}</StyledArticleTitle>
            </StyledArticleCard>
          )
        })}
      </StyledArticleList>
    </StyledArticleListWrapper>
  )
}

ArticleList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({ children: PropTypes.node }))
    .isRequired
}

export default ArticleList
