import { useContext, useMemo, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PortableText } from '@portabletext/react'

import { getImageUrl } from 'sanityClient.js'
import { Context } from 'store'
import {
  ImagesBlock,
  TitleAndTextBlock,
  TextBlock,
  VideoBlock,
  MediaBlock,
  ContactBlock
} from 'components'
import { Body4, Body2, Body1, SubTitle1 } from 'assets/common/typography'
import { formatStringForPath, setWorkEntryPath } from 'assets/common/helper'
import {
  StyledHeroBackground,
  StyledHeroDescriptionContainer,
  StyledHeroContainer,
  StyledHeroTitleContainer,
  StyledHeroTitle,
  StyledHeroImageContainer,
  StyledWorkContentContainer,
  StyledMainContentBackground,
  StyledContentBackground,
  StyledResultsAndAwardsContainer,
  StyledResults,
  StyledAwards,
  StyledTitle,
  StyledReadNextWrapper,
  StyledReadNextContainer,
  StyledNextContainer,
  StyledReadNextArticleTitle,
  StyledArrow
} from './WorkEntry.styled'

const WorkEntry = () => {
  const navigate = useNavigate()
  const { client: workEntryClient, title: workEntryTitle } = useParams()

  const {
    state: { portfolio }
  } = useContext(Context)

  const [previousArticle, setPreviousArticle] = useState()
  const [nextArticle, setNextArticle] = useState()

  // ID is more correct but Cheil wants the url to be direct and short so we match the url with the client and title
  // in the same way they're processed during creation
  const workEntry = useMemo(() => {
    return portfolio.find((work) => {
      return (
        formatStringForPath(work.client) === workEntryClient &&
        formatStringForPath(work.title) === workEntryTitle
      )
    })
  }, [portfolio, workEntryClient, workEntryTitle])

  useEffect(() => {
    const currentIndex = portfolio.indexOf(workEntry)
    const previousElement =
      currentIndex > 0
        ? portfolio[currentIndex - 1]
        : portfolio[portfolio.length - 1]

    const nextElement =
      currentIndex < portfolio.length - 1
        ? portfolio[currentIndex + 1]
        : portfolio[0]

    setPreviousArticle(previousElement)
    setNextArticle(nextElement)
  }, [portfolio, workEntry])

  if (!workEntry) return null

  const {
    title,
    client,
    description,
    hero_image,
    case_color,
    awards,
    content,
    results,
    hero_video_link,
    contact_description,
    contact_details
  } = workEntry

  const columnsComponents = {
    types: {
      textBlock: TextBlock,
      titleAndText: TitleAndTextBlock,
      imagesBlock: ImagesBlock,
      videosBlock: MediaBlock
    }
  }

  const handleNavigateArticle = (article) => {
    navigate(`/work/${setWorkEntryPath(article)}`)
  }

  return (
    <>
      <StyledHeroBackground bgcolor={(case_color && case_color.hex) || '#555'}>
        <StyledHeroContainer>
          <Body2>{client && client}</Body2>
          <StyledHeroTitleContainer>
            <StyledArrow onClick={() => handleNavigateArticle(previousArticle)}>
              ←
            </StyledArrow>
            <StyledHeroTitle>{title && title}</StyledHeroTitle>
            <StyledArrow onClick={() => handleNavigateArticle(nextArticle)}>
              →
            </StyledArrow>
          </StyledHeroTitleContainer>
          <StyledHeroDescriptionContainer>
            <PortableText
              value={description}
              components={{
                block: {
                  normal: SubTitle1
                }
              }}
            />
          </StyledHeroDescriptionContainer>
          {hero_image && (
            <StyledHeroImageContainer>
              <VideoBlock
                srcAsset={getImageUrl(hero_image).url()}
                url={hero_video_link}
                title={title}
              />
            </StyledHeroImageContainer>
          )}
        </StyledHeroContainer>
      </StyledHeroBackground>

      {content && (
        <>
          <StyledMainContentBackground>
            {content.slice(0, 2).map((item) => (
              <StyledWorkContentContainer key={item._key}>
                <PortableText value={item} components={columnsComponents} />
              </StyledWorkContentContainer>
            ))}
          </StyledMainContentBackground>

          {content.slice(2) && (
            <StyledContentBackground>
              {content.slice(2).map((item) => (
                <StyledWorkContentContainer key={item._key}>
                  <PortableText value={item} components={columnsComponents} />
                </StyledWorkContentContainer>
              ))}
            </StyledContentBackground>
          )}
        </>
      )}

      {(results || awards) && (
        <StyledContentBackground>
          <StyledResultsAndAwardsContainer>
            {results && (
              <StyledResults>
                <StyledTitle>Result</StyledTitle>
                {results &&
                  results.map(
                    ({ _key, amount, description: resultDescription }) => (
                      <SubTitle1 className="result" key={_key}>
                        <strong>{amount}</strong> - {resultDescription}
                      </SubTitle1>
                    )
                  )}
              </StyledResults>
            )}

            {awards && (
              <StyledAwards>
                <StyledTitle>Awarded</StyledTitle>
                {awards &&
                  awards.map((award) => (
                    <Body1 className="awarded" key={award}>
                      {`🏆 \u00A0 ${award}`}
                    </Body1>
                  ))}
              </StyledAwards>
            )}
          </StyledResultsAndAwardsContainer>
        </StyledContentBackground>
      )}

      <StyledContentBackground>
        <ContactBlock details={{ contact_description, contact_details }} />
      </StyledContentBackground>

      <StyledReadNextWrapper>
        {previousArticle && previousArticle.hero_image && (
          <StyledReadNextContainer
            onClick={() => handleNavigateArticle(previousArticle)}
            aria-hidden="true"
            bgimage={getImageUrl(previousArticle.hero_image.asset._ref).url()}
          >
            <Body4>← Previous Project</Body4>
            <StyledReadNextArticleTitle>
              {previousArticle.title}
            </StyledReadNextArticleTitle>
          </StyledReadNextContainer>
        )}

        {nextArticle && nextArticle.hero_image && (
          <StyledNextContainer
            onClick={() => handleNavigateArticle(nextArticle)}
            aria-hidden="true"
            bgimage={getImageUrl(nextArticle.hero_image.asset._ref).url()}
          >
            <Body4>Next Project →</Body4>
            <StyledReadNextArticleTitle>
              {nextArticle.title && nextArticle.title}
            </StyledReadNextArticleTitle>
          </StyledNextContainer>
        )}
      </StyledReadNextWrapper>
    </>
  )
}

export default WorkEntry
