import styled, { css } from 'styled-components'
import { StyledCenteredContainer } from 'assets/common/mixin'

export const StyledImagesContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin: 5rem 0;

  img {
    width: 100%;
    height: auto;
  }

  ${({ amount }) => {
    if (amount === 2) {
      return css`
        img {
          min-width: 40rem;
          height: 100%;
        }

        @media (max-width: 1024px) {
          img {
            min-width: unset;
          }
          flex-direction: column;
        }
      `
    }
    return null
  }}

  ${({ amount }) => {
    if (amount === 3) {
      return css`
        display: flex;
        justify-content: space-between;

        @media (max-width: 500px) {
          flex-direction: column;
        }
      `
    }
    return null
  }}

  ${({ imageStyle }) => {
    if (imageStyle === 'full-width') {
      return css`
        display: block;
        ${StyledCenteredContainer}
        margin-left: -9.3%;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        max-width: 100vw;
        width: 100vw;
        /* margin-top: -3rem;
        margin-bottom: -3rem; */
      `
    }
    return null
  }}
`
