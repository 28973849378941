import React, { useContext } from 'react'
import { PortableText } from '@portabletext/react'
import { getImageUrl } from 'sanityClient.js'

import { Context } from 'store'
import { Button, HelmetSEO } from 'components'
import { showContactForm } from 'store/actions'

import { SubTitle2 } from 'assets/common/typography'

import {
  StyledContactWrapper,
  StyledContactContainer,
  StyledContactDescription,
  StyledLocationsContainer,
  StyledLocationsList,
  StyledLocation,
  StyledLocationContent
} from './Contact.styled'

const helmetCopy = {
  title: 'Contact information | Cheil Benelux',
  description:
    'Find out how you can reach out to us via phone or e-mail. Or come and have a coffee at our Amsterdam, Schiphol or Belgium office.',
  keywords:
    'cheil benelux adres, cheil telefoonnummer, cheil adres, cheil e-mail, cheil benelux locations, cheil phone number, cheil address'
}

const Contact = () => {
  const {
    dispatch,
    state: { footer }
  } = useContext(Context)

  return (
    <StyledContactWrapper>
      <StyledContactContainer>
        <HelmetSEO copy={helmetCopy} />
        <h2>{footer.phone}</h2>
        <div
          className="email"
          aria-hidden="true"
          onClick={() => dispatch(showContactForm(true))}
        >
          <h3>{footer.email}</h3>
        </div>
        <PortableText
          value={footer.contact_description}
          components={{
            block: {
              normal: StyledContactDescription
            }
          }}
        />
        <Button
          handleClick={() => dispatch(showContactForm(true))}
          textButton="Leave us a message"
          isNoArrow
          isGradient
        />
      </StyledContactContainer>

      <StyledLocationsContainer>
        <h4>Locations</h4>
        <StyledLocationsList>
          {footer.addresses.map((loc) => (
            <StyledLocation key={loc._key}>
              {loc.office_photo && (
                <img
                  src={getImageUrl(loc.office_photo).url()}
                  alt={loc.title}
                />
              )}
              <SubTitle2>{loc.title}</SubTitle2>
              <PortableText
                value={loc.info}
                components={{
                  block: {
                    normal: StyledLocationContent
                  }
                }}
              />
              <p>{loc.phone}</p>
            </StyledLocation>
          ))}
        </StyledLocationsList>
      </StyledLocationsContainer>
    </StyledContactWrapper>
  )
}

export default Contact
