import styled, { css } from 'styled-components'

export const StyledTitleAndTextBlockContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin: 5rem 0;

  ${({ position }) => {
    if (position === 'full-width') {
      return css`
        flex-direction: column;
        align-items: center;
      `
    }
    return null
  }}

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 0;
  }
`

export const StyledTitleBlock = styled.div`
  display: flex;
  min-width: 35rem;

  ${({ position }) => {
    if (position === 'full-width') {
      return css`
        order: -1;
        margin-bottom: -3rem;
      `
    }

    if (position === 'left') {
      return css`
        order: 1;
      `
    }

    if (position === 'right') {
      return css`
        order: 2;
      `
    }

    return null
  }}

  @media (max-width: 900px) {
    min-width: 0;
    margin-bottom: 0;
  }
`

export const StyledTextBlock = styled.div`
  ${({ position }) => {
    if (position === 'full-width') {
      return css`
        order: -1;
      `
    }

    if (position === 'left') {
      return css`
        order: 2;
        padding-top: 1rem;
      `
    }

    if (position === 'right') {
      return css`
        order: 1;
        padding-top: 1rem;
      `
    }

    return null
  }}

  @media (max-width: 900px) {
    padding-top: 0;
  }
`

export const StyledListItem = styled.li`
  font-size: 1.625rem;
  line-height: 175%;

  @media (max-width: 900px) {
    font-size: 0.9375rem;
  }
`

export const StyledLink = styled.a`
  color: inherit;
  text-decoration: underline;
`
