import styled from 'styled-components'
import { colors } from 'assets/common/colors'
import { StyledCenteredContainer } from 'assets/common/mixin'

const { white, black111 } = colors

export const StyledPageNotFoundWrapper = styled.div`
  padding: 18.75rem 7.5rem;
  background: ${black111};
  height: 100vh;
  color: ${white};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  @media (max-width: 900px) {
    padding: 15.625rem 3.125rem;
  }
`

export const StyledPageNotFoundContainer = styled(StyledCenteredContainer)`
  @media (max-width: 900px) {
    width: 100%;
  }
`
