import styled from 'styled-components'
import { colors } from 'assets/common/colors'
import { Body1 } from 'assets/common/typography'
import { StyledCenteredContainer } from 'assets/common/mixin'

const { white, gray3, black, black111 } = colors

export const StyledHeroBackground = styled.div`
  background: linear-gradient(
    ${({ bgcolor }) => bgcolor} calc(80% - 10px),
    ${black111} 0
  );
  text-align: center;
  margin-top: 0;
`

export const StyledHeroContainer = styled(StyledCenteredContainer)`
  padding-top: 18rem;

  @media (max-width: 900px) {
    padding-top: 13rem;
  }
`

export const StyledHeroTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledHeroTitle = styled.h2`
  width: 60%;
`

export const StyledHeroDescriptionContainer = styled.div`
  width: 75%;
  margin: auto;

  @media (max-width: 900px) {
    width: 100%;
  }
`

export const StyledHeroImageContainer = styled.div`
  margin-top: 8rem;
  position: relative;

  img {
    width: 100%;
    height: auto;
  }
`

export const StyledArrow = styled.div`
  cursor: pointer;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.96px;
`

export const StyledMainContentBackground = styled.div`
  padding-bottom: 5rem;
`

export const StyledWorkContentContainer = styled(StyledCenteredContainer)`
  padding: 1rem 5rem;

  @media (max-width: 900px) {
    padding: 1rem 3.75rem;
  }
  @media (max-width: 500px) {
    padding: 1rem 2rem;
  }
`

export const StyledContentBackground = styled.div`
  background: ${white};
  color: ${black};
  margin-top: -3rem;
`

export const StyledResultsAndAwardsContainer = styled(StyledCenteredContainer)`
  display: flex;
  gap: 5rem;
  padding-bottom: 10rem;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 2rem;
  }
`
export const StyledResults = styled.div`
  width: 55%;
  @media (max-width: 900px) {
    width: 100%;
  }
`
export const StyledAwards = styled.div`
  width: 45%;
  @media (max-width: 900px) {
    width: 100%;
  }
`

export const StyledTitle = styled(Body1)`
  padding-bottom: 1rem;
  color: ${gray3};
`

// export const StyledContent = styled.div`
//   padding: 0.5rem 0;
//   line-height: 150%;
//   /* &.result {
//     font-size: 1.625rem;
//   }
//   &.awarded {
//     font-size: 1.125rem;
//     text-transform: uppercase;
//   } */
//   @media (max-width: 900px) {
//     gap: 0.5rem;
//     &.result {
//       font-size: 1.125rem;
//     }
//     &.awarded {
//       font-size: 0.9375rem;
//     }
//   }
// `

export const StyledReadNextBackground = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledReadNextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 17.625rem;
  @media (max-width: 900px) {
    min-height: 13.3125rem;
  }
`

export const StyledReadNextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%),
    url(${(props) => props.bgimage});
  background-size: cover;
  padding: 0 3rem;
  cursor: pointer;
  @media (max-width: 900px) {
    padding: 0 1.5rem;
    background-position: center;
    justify-content: flex-start;
    padding-top: 10%;
  }
`

export const StyledNextContainer = styled(StyledReadNextContainer)`
  text-align: end;
`

export const StyledReadNextArticleTitle = styled.h4`
  margin-top: -0.5rem;
`
