import React from 'react'
import { oneOfType, arrayOf, node } from 'prop-types'
import LazyLoad from 'react-lazyload'

const LazyLoaded = ({ children }) => {
  return (
    <LazyLoad offset={100} height="100%" once>
      {children}
    </LazyLoad>
  )
}

LazyLoaded.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired
}

export default LazyLoaded
