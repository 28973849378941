import { useContext, useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PortableText } from '@portabletext/react'
import { VideoModal } from 'sebn-library'
import { Parallax } from 'react-scroll-parallax'

import Lottie from 'react-lottie'
import { motion } from 'framer-motion'
import { getImageUrl, getFileUrl } from 'sanityClient.js'
import { Context } from 'store'
import {
  HelmetSEO,
  ImageWithTextOverlay,
  Button,
  ServicesIntro
} from 'components'

import { SubTitle1 } from 'assets/common/typography'
import { ReactComponent as PlayReelCircle } from 'assets/images/play-reel-circle.svg'
import emojiSun from 'assets/lotties/emojiSun'
import emojiHands from 'assets/lotties/emojiHands'
// import sunIcon from 'assets/images/sun.gif'
// import lolIcon from 'assets/images/lol.gif'
// import heartIcon from 'assets/images/heart.gif'
import liquidBG from 'assets/images/liquidbg_v2.mp4'
import { createPortal } from 'react-dom'
import {
  StyledHeroBackground,
  // StyledEmojiWrapper,
  // StyledEmojiContainer,
  // StyledSpan,
  StyledHeroVideo,
  StyledHeroTitle,
  StyledPlayReelCircle,
  StyledSubHeroBackground,
  StyledHeroContainer,
  StyledSubHeroContainer,
  StyledSubHeroTitle,
  StyledSubHeroDescriptionContainer,
  StyledSubHeroDescription,
  StyledLatestWorkBackground,
  StyledLatestWorkContainer,
  StyledLatestWork,
  StyledLatestWorkButtonContainer,
  StyledWorkImageContainer,
  StyledTeamBackground,
  StyledTeamContainer,
  StyledTeamContent,
  StyledTeamPhotosContainer,
  StyledTeamPhoto,
  StyledAboutContainer,
  StyledVideoBackground,
  StyledTextOverlay,
  StyledAboutDescription,
  StyledAboutBackground,
  StyledSunIcon,
  StyledHeartIcon,
  StyledVideoModal
} from './Homepage.styled'
import { StyledLoaderContainer } from '../../App.styled'
import Loader from '../../components/Loader/Loader'

const helmetCopy = {
  title: 'Home | Cheil Benelux',
  description:
    'We believe in the power of merging advertising, retail and digital into one big exciting opportunity. Find out how we create magic.',
  keywords:
    'cheil, cheil benelux, cheil nederland, cheil netherlands, cheil belgie, cheil belgium, cheil amsterdam, cheil schiphol, marketing bureau, marketing agency, advertising, campaigns, campagnes, retail, e-commerce, digital, strategy'
}

const getRelativeCoordinates = (event, referenceElement) => {
  const position = {
    x: event.pageX,
    y: event.pageY
  }

  const offset = {
    left: referenceElement.offsetLeft,
    top: referenceElement.offsetTop,
    width: referenceElement.clientWidth,
    height: referenceElement.clientHeight
  }

  let reference = referenceElement.offsetParent

  while (reference) {
    offset.left += reference.offsetLeft
    offset.top += reference.offsetTop
    reference = reference.offsetParent
  }

  return {
    x: position.x - offset.left,
    y: position.y - offset.top,
    width: offset.width,
    height: offset.height,
    centerX: (position.x - offset.left - offset.width / 2) / (offset.width / 2),
    centerY: (position.y - offset.top - offset.height / 2) / (offset.height / 2)
  }
}

const Homepage = () => {
  // const { width } = useWindowSize()
  const navigate = useNavigate()
  const {
    state: { homepage, portfolio }
  } = useContext(Context)

  const heroRef = useRef()
  const liquidVideoRef = useRef()

  const [showPopUp, setShowPopUp] = useState(false)

  const {
    hero_title,
    hero_video,
    hero_image,
    hero_image2,
    company_description,
    portfolio_title,
    about_description,
    about_title,
    team_description,
    team_title,
    team_photos
  } = homepage

  const [mousePosition, setMousePosition] = useState({})
  const handleMotionMouseMove = (e) => {
    setMousePosition(getRelativeCoordinates(e, heroRef.current))
  }

  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min
  }

  const defaultOptions = (emoji) => {
    return {
      loop: true,
      autoplay: true,
      animationData: emoji
    }
  }

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (heroRef && heroRef.current) {
      heroRef.current
        .play()
        .then(() => {})
        .catch(() => {
          setIsLoading(false)
        })
    }
    if (liquidVideoRef && liquidVideoRef.current) {
      liquidVideoRef.current
        .play()
        .then(() => {})
        .catch(() => {
          // Video couldn't play, low power play button showing.
        })
    }
    setMousePosition({
      x: heroRef.current.clientWidth / 2,
      y: heroRef.current.clientHeight / 2
    })
  }, [])

  return (
    <>
      <HelmetSEO copy={helmetCopy} />
      {isLoading && (
        <StyledLoaderContainer style={{ position: 'absolute', zIndex: 2 }}>
          <Loader isInverted />
        </StyledLoaderContainer>
      )}
      <StyledHeroBackground
        onClick={() => setShowPopUp(!showPopUp)}
        onMouseMove={(e) => handleMotionMouseMove(e)}
      >
        <StyledHeroContainer>
          <StyledHeroTitle>{hero_title}</StyledHeroTitle>
          {showPopUp === true && (hero_video || hero_image2)
            ? createPortal(
                <StyledVideoModal>
                  <VideoModal
                    handleClose={() => setShowPopUp(false)}
                    url={hero_video || getFileUrl(hero_image2.asset._ref)}
                    title={hero_title}
                  />
                </StyledVideoModal>,
                document.body
              )
            : null}
        </StyledHeroContainer>
        {hero_image && (
          <div>
            <StyledPlayReelCircle
              animate={{
                x: mousePosition.x,
                y: mousePosition.y
              }}
              whileHover={{
                scale: 1.3
              }}
              transition={{
                type: 'tween'
              }}
              onClick={() => setShowPopUp(true)}
            >
              <PlayReelCircle />
            </StyledPlayReelCircle>
            <StyledHeroVideo
              ref={heroRef}
              isBlurred={showPopUp}
              preload="true"
              playsInline
              muted
              loop
              id="videoId"
              onCanPlay={() => setIsLoading(false)}
            >
              <source
                src={getFileUrl(hero_image.asset._ref)}
                type="video/mp4"
              />
            </StyledHeroVideo>
          </div>
        )}
      </StyledHeroBackground>
      <StyledSubHeroBackground>
        <StyledSubHeroContainer>
          <StyledSubHeroTitle>
            <span>
              Gr{' '}
              <StyledSunIcon>
                <Lottie class="sunIcon" options={defaultOptions(emojiSun)} />
              </StyledSunIcon>{' '}
              wth
            </span>{' '}
            for
            <br />
            Challenger
            <br />
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '2rem'
              }}
            >
              <StyledHeartIcon>
                <Lottie
                  class="heartIcon"
                  options={defaultOptions(emojiHands)}
                />
              </StyledHeartIcon>
              Brands.
            </span>
          </StyledSubHeroTitle>
          <StyledSubHeroDescriptionContainer>
            <PortableText
              value={company_description}
              components={{ block: { normal: StyledSubHeroDescription } }}
            />
          </StyledSubHeroDescriptionContainer>
        </StyledSubHeroContainer>
      </StyledSubHeroBackground>
      <StyledLatestWorkBackground>
        <StyledLatestWorkContainer>
          <Parallax speed={-10}>
            <h4>{portfolio_title}</h4>
            <StyledLatestWork>
              {portfolio.slice(0, 3).map((item, index) => (
                <StyledWorkImageContainer key={item._id} position={index}>
                  <ImageWithTextOverlay item={item} isOnHomepage />
                </StyledWorkImageContainer>
              ))}
            </StyledLatestWork>
            <StyledLatestWorkButtonContainer>
              <Button
                handleClick={() => navigate('/work')}
                textButton="Work archive"
                isGradient
              />
            </StyledLatestWorkButtonContainer>
          </Parallax>
        </StyledLatestWorkContainer>
      </StyledLatestWorkBackground>
      <ServicesIntro />
      <StyledTeamBackground>
        <StyledTeamContainer>
          <StyledTeamPhotosContainer>
            {team_photos.map((i, index) => (
              <motion.div key={i._key}>
                <StyledTeamPhoto
                  src={getImageUrl(i).url()}
                  alt={`Team outing ${index + 1}`}
                  position={index}
                  whileHover={{
                    x: getRandomArbitrary(-17, 17),
                    y: getRandomArbitrary(-17, -4),
                    rotate: getRandomArbitrary(-5, 6)
                  }}
                  transition={{
                    ease: 'linear',
                    duration: 0.2
                  }}
                />
              </motion.div>
            ))}
          </StyledTeamPhotosContainer>
          <StyledTeamContent>
            <h4>{team_title}</h4>
            <SubTitle1>{team_description}</SubTitle1>
            <Button
              textButton="Join the team"
              handleClick={() => navigate('/people')}
              isGradient
              whiteBg
            />
          </StyledTeamContent>
        </StyledTeamContainer>
      </StyledTeamBackground>
      <StyledAboutBackground>
        <Parallax translateY={[-35, 35]} speed={-20}>
          <StyledVideoBackground
            ref={liquidVideoRef}
            preload="true"
            playsInline
            muted
            loop
          >
            <source src={liquidBG} type="video/mp4" />
          </StyledVideoBackground>
        </Parallax>
        <StyledAboutContainer>
          <StyledTextOverlay>
            <h2>{about_title}</h2>
            <PortableText
              value={about_description}
              components={{ block: { normal: StyledAboutDescription } }}
            />
            <Button
              textButton="About us"
              handleClick={() => navigate('/about')}
              darkBg
              whiteBg
            />
          </StyledTextOverlay>
        </StyledAboutContainer>
      </StyledAboutBackground>
    </>
  )
}

export default Homepage
