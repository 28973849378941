import axios from 'axios'

// to clear cache:
// https://5hwlauin22.execute-api.eu-central-1.amazonaws.com/dev/update-data
// https://99tzkt8tnj.execute-api.eu-central-1.amazonaws.com/production/update-data

const apiUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://99tzkt8tnj.execute-api.eu-central-1.amazonaws.com/production'
    : 'https://5hwlauin22.execute-api.eu-central-1.amazonaws.com/dev'

const fetchData = async () => {
  try {
    const { data } = await axios.get(`${apiUrl}/get-data`)
    if (!data) return null

    return data.data
  } catch (err) {
    throw new Error(err)
  }
}

export default fetchData
