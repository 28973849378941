import PropTypes from 'prop-types'
import { getFileUrl } from 'sanityClient.js'
import { StyledSwiperContainer } from 'components/SwiperCarousel/SwiperCarousel.styled'

import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/autoplay'
import { useEffect, useRef, useState } from 'react'

const SwiperCarousel = ({ media }) => {
  // These disastrous lines are a fix for a bug where slidesPerView="auto" does not start autoplaying after opening a new tab (not a refresh) on first pain, I couldn't figure out why this was happening and so far this has been the only fix I could find
  const ref = useRef(null)
  const [autoplayConfig, setAutoplayConfig] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setAutoplayConfig({
        delay: 0,
        disableOnInteraction: false,
        waitForTransition: true,
        stopOnLastSlide: false
      })
      if (ref.current.swiper) {
        ref.current?.swiper.autoplay.start()
      }
    }, 400)
  }, [])

  return (
    <StyledSwiperContainer>
      <Swiper
        ref={ref}
        modules={[Autoplay]}
        autoplay={autoplayConfig}
        spaceBetween={50}
        loop
        freeMode
        grabCursor
        slidesPerView="auto"
        speed={5000}
        initialSlide={0}
      >
        {media.map((image, index) => (
          <SwiperSlide key={image._key} virtualIndex={index}>
            <img src={getFileUrl(image.asset._ref)} alt="team outing" />
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledSwiperContainer>
  )
}

SwiperCarousel.propTypes = {
  media: PropTypes.arrayOf(
    PropTypes.shape({
      asset: PropTypes.shape({
        _ref: PropTypes.string.isRequired,
        _type: PropTypes.string.isRequired
      })
    })
  ).isRequired
}

export default SwiperCarousel
