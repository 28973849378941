import React from 'react'
import PropTypes from 'prop-types'
import { getImageUrl } from 'sanityClient.js'

const ImageBlock = ({ value, name }) => {
  return (
    <div>
      {value && value.asset && (
        <img
          src={getImageUrl(value).url()}
          alt={name || value || ' '}
          loading="lazy"
        />
      )}
    </div>
  )
}

ImageBlock.propTypes = {
  value: PropTypes.shape({
    asset: PropTypes.shape({
      _ref: PropTypes.string.isRequired,
      _type: PropTypes.string.isRequired,
      _key: PropTypes.string
    })
  }).isRequired,
  name: PropTypes.string.isRequired
}

export default ImageBlock
