import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'
import { ReactComponent as EnvelopIcon } from 'assets/images/envelop.svg'
import { ImageBlock } from 'components'
import { Context } from 'store'
import { setContactPerson, showContactForm } from 'store/actions'
import {
  StyledContactPersonContainer,
  StyledContactDetailsContainer,
  StyledContactDetails,
  StyledContactText,
  StyledContactNote,
  StyledTextEmojiContainer
} from './ContactPersonBlock.styled'

const ContactPersonBlock = ({
  data,
  note = 'Drop a note',
  isFooter,
  isWorkEntry = false,
  isServiceCard
}) => {
  const { dispatch } = useContext(Context)
  const { title: contactTitle, contact_name, contact_phone, icon } = data

  const handleClick = () => {
    dispatch(showContactForm(true))
    dispatch(setContactPerson(data))
  }

  return (
    <StyledContactPersonContainer isWorkEntry={isWorkEntry}>
      <ImageBlock value={icon} name={contact_name} />
      <div>
        {isFooter ? (
          <StyledContactDetailsContainer>
            <StyledContactText bold>{contactTitle}</StyledContactText>
            <StyledContactDetails>
              <StyledContactText>{contact_name}</StyledContactText>
              <StyledContactText>{contact_phone}</StyledContactText>
            </StyledContactDetails>
          </StyledContactDetailsContainer>
        ) : (
          <StyledContactDetailsContainer>
            <StyledContactText bold>{contact_name}</StyledContactText>
            <StyledContactText isServiceCard={isServiceCard}>
              {contactTitle}
            </StyledContactText>
          </StyledContactDetailsContainer>
        )}
        <StyledTextEmojiContainer
          onClick={handleClick}
          isWorkEntry={isWorkEntry}
        >
          <StyledContactNote>
            <EnvelopIcon fill={isWorkEntry ? '#01c8ef' : '#fff'} /> {note}
          </StyledContactNote>
        </StyledTextEmojiContainer>
      </div>
    </StyledContactPersonContainer>
  )
}

ContactPersonBlock.propTypes = {
  data: PropTypes.shape({
    contact_name: PropTypes.string.isRequired,
    contact_phone: PropTypes.string,
    title: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      asset: PropTypes.shape({
        _ref: PropTypes.string.isRequired
      })
    })
  }).isRequired,
  note: PropTypes.string,
  isFooter: PropTypes.bool,
  isWorkEntry: PropTypes.bool,
  isServiceCard: PropTypes.bool
}

ContactPersonBlock.defaultProps = {
  note: 'Drop a note',
  isWorkEntry: false,
  isFooter: false,
  isServiceCard: false
}

export default ContactPersonBlock
