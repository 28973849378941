import styled, { css } from 'styled-components'
import { colors } from 'assets/common/colors'

const { white } = colors

export const StyledImagesWrapper = styled.div`
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    opacity: 0.5;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
    z-index: 2;
    pointer-events: none;
  }
`

export const StyledImage = styled.img`
  width: 100%;
  display: block;
  height: auto;
  transition: transform 2s cubic-bezier(0.2, 0.8, 0.2, 1);
  position: relative;
  z-index: 1;

  &:hover {
    transform: scale(1.1);
  }

  ${({ isOnHomepage }) =>
    isOnHomepage &&
    css`
      width: 28.2rem;
      height: 35.36881rem;
      object-fit: cover;

      @media (max-width: 500px) {
        min-height: unset;
        min-width: unset;
      }
    `}
`

export const MinHeightStyledImage = styled(StyledImage)`
  min-height: 324px;

  @media (max-width: 400px) {
    min-height: 200px;
  }
`

export const StyledText = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  color: ${white};
  left: 1.875rem;
  bottom: 1.5rem;
  z-index: 2;

  p {
    &:first-child {
      opacity: 0.7;
    }
  }
`
