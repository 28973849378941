import styled from 'styled-components'
import { SubTitle1 } from 'assets/common/typography'

export const StyledBlockQuote = styled.div`
  display: flex;
  gap: 2rem;
`

export const StyledBlockQuoteTextContainer = styled.div`
  width: 80%;
`

export const StyledBlockQuoteText = styled(SubTitle1)`
  margin: 0;
`
