import {
  CHANGE_IS_LOADING,
  SET_DATA,
  SHOW_CONTACT_FORM,
  SET_CONTACT_PERSON,
  SET_GEO_DATA
} from './actionNames'

const reducer = (state, { type, payload }) => {
  switch (type) {
    case CHANGE_IS_LOADING:
      return {
        ...state,
        isLoading: payload
      }

    case SET_DATA:
      return {
        ...state,
        about: payload.about,
        footer: payload.footer,
        homepage: payload.homepage,
        jobs: payload.jobs,
        news: payload.news,
        people: payload.people,
        policy: payload.policy,
        terms: payload.terms,
        portfolio: payload.portfolio,
        services: payload.services
      }

    case SET_CONTACT_PERSON:
      return {
        ...state,
        contactPerson: payload
      }

    case SET_GEO_DATA:
      return {
        ...state,
        geoInfo: payload
      }

    case SHOW_CONTACT_FORM:
      return {
        ...state,
        showForm: payload
      }

    default:
      return state
  }
}
export default reducer
