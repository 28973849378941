import { useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import * as TWEEN from '@tweenjs/tween.js'

const OneAnimation = () => {
  const canvasRef = useRef()

  // eslint-disable-next-line
  const [renderer, setRenderer] = useState(
    new THREE.WebGLRenderer({ alpha: true, antialias: true })
  )

  const getCameraAspectRatio = () => {
    // elongated renderer at these sizes, compensated with a camera aspect ratio w/h
    if (window.innerHeight > 1000 && window.innerWidth > 1440) {
      return 1.55
    }
    // just a flat aspect ratio in every situation for now
    return 1
  }

  // eslint-disable-next-line
  const [camera, setCamera] = useState(
    new THREE.PerspectiveCamera(75, getCameraAspectRatio(), 0.1, 100)
  )

  const adjustSizingPerspectives = () => {
    // camera.position.z = 3.8
    if (window.innerWidth < 600) {
      camera.position.set(1, 0, 3.6) // Set the camera position for mobile view
    } else {
      camera.position.set(0, 0, 5) // Set the camera position for other viewports
    }

    // Try to get the different sizes right and pray the pixel gods don't overlap
    if (window.innerHeight > 1000 && window.innerWidth > 1440) {
      renderer.domElement.style.top = '10rem'
      renderer.setSize(1400, 900)
    } else if (window.innerHeight > window.innerWidth * 1.5) {
      renderer.domElement.style.top = '26rem'
      renderer.setSize(window.innerWidth, window.innerWidth)
    } else if (window.innerHeight > window.innerWidth * 1.25) {
      renderer.domElement.style.top = '22rem'
      renderer.setSize(window.innerWidth, window.innerWidth)
    } else if (window.innerHeight > window.innerWidth) {
      renderer.domElement.style.top = '5rem'
      renderer.setSize(window.innerWidth, window.innerWidth)
    } else {
      renderer.setSize(window.innerHeight, window.innerHeight)
    }
    if (window.innerWidth > 1440) {
      // renderer.domElement.style.left = 'unset'
      // renderer.domElement.style.right = 0
    }
    if (window.innerWidth < 300) {
      renderer.domElement.style.top = '35rem'
    }
  }

  useEffect(() => {
    if (canvasRef.current.querySelector('canvas')) {
      canvasRef.current.querySelector('canvas').remove()
    }

    // helper function to convert degrees to radians
    const toRadians = (angle) => angle * (Math.PI / 180)

    // Create a scene
    const scene = new THREE.Scene()

    adjustSizingPerspectives()
    canvasRef.current.appendChild(renderer.domElement)

    // Create a raycaster
    const raycaster = new THREE.Raycaster()
    const mouse = new THREE.Vector2()

    // Key light
    const keyLight = new THREE.DirectionalLight(new THREE.Color(0xffffff), 0.75)
    keyLight.position.set(-100, 0, 100)

    // Fill light
    const fillLight = new THREE.DirectionalLight(new THREE.Color(0xffffff), 1)
    fillLight.position.set(100, 0, 100)

    // Back light
    const backLight = new THREE.DirectionalLight(0xffffff, 0.25)
    backLight.position.set(100, 0, -100)

    // Add lights to the scene
    scene.add(keyLight)
    scene.add(fillLight)
    scene.add(backLight)

    // Create the shape of the right angled triangle
    const triangleShape = new THREE.Shape()
    triangleShape.moveTo(0, 0)
    triangleShape.lineTo(1, 0)
    triangleShape.lineTo(0, 1)
    triangleShape.lineTo(0, 0)

    // Extrude the triangle into a 3D shape
    const extrudeSettings = { depth: 1, bevelEnabled: false }
    const triangleGeometry = new THREE.ExtrudeGeometry(
      triangleShape,
      extrudeSettings
    )

    // Create the two triangles with different materials
    const darkGrayMaterial = new THREE.MeshPhysicalMaterial({ color: 0x000000 })

    // Create a parent group
    const parentGroup = new THREE.Group()

    for (let i = 0; i < 4; i++) {
      const texture = new THREE.TextureLoader().load(
        `https://sebn.ams3.cdn.digitaloceanspaces.com/src/cheil/${i + 1}.jpg`,
        (txture) => {
          /* eslint-disable no-param-reassign */
          txture.generateMipmaps = false
          THREE.ColorManagement.enabled = true
          txture.wrapT = THREE.ClampToEdgeWrapping
          txture.colorSpace = THREE.SRGBColorSpace
          txture.dispose()
          /* eslint-enable no-param-reassign */
        }
      )

      const pinkMaterial = new THREE.MeshLambertMaterial({ map: texture })

      const pinkTriangle = new THREE.Mesh(triangleGeometry, pinkMaterial)
      const darkGrayTriangle = new THREE.Mesh(
        triangleGeometry,
        darkGrayMaterial
      )

      // Position the triangles to form a cube
      pinkTriangle.position.set(0.5, -0.5, 0)
      darkGrayTriangle.position.set(-0.5, 0.5, 0)

      // Rotate triangles together 90 degrees clockwise around Z-axis
      pinkTriangle.rotation.z = Math.PI / 2
      darkGrayTriangle.rotation.z = -Math.PI / 2

      // Create a group and add the triangles to the group
      const group = new THREE.Group()
      group.add(pinkTriangle)
      group.add(darkGrayTriangle)

      parentGroup.add(group)
    }

    // Position the cubes according to the specified matrix
    parentGroup.children[0].position.set(-1, 3, 0)
    parentGroup.children[1].position.set(0, 3, 0)
    parentGroup.children[2].position.set(0, 2, 0)
    parentGroup.children[3].position.set(0, 1, 0)

    // Store the initial position and rotation after setting positions
    for (let i = 0; i < 4; i++) {
      parentGroup.children[i].initialPosition =
        parentGroup.children[i].position.clone()
      parentGroup.children[i].initialRotation =
        parentGroup.children[i].rotation.clone()
    }

    parentGroup.rotation.z = -0.25
    parentGroup.rotation.x = -0.05
    parentGroup.rotation.y = 0.15
    parentGroup.position.set(0.75, -2.3, 0)

    scene.add(parentGroup)

    let isDragging = false
    let previousMousePosition = { x: 0, y: 0 }

    const onCompleteMove = (intersect) => {
      return () => {
        setTimeout(() => {
          new TWEEN.Tween(intersect.object.parent.position)
            .to(
              {
                x: intersect.object.parent.initialPosition.x,
                y: intersect.object.parent.initialPosition.y,
                z: intersect.object.parent.initialPosition.z
              },
              1500
            )
            .easing(TWEEN.Easing.Quartic.In)
            .start()
        }, 100)
      }
    }

    const onCompleteRotate = (intersect) => {
      return () => {
        setTimeout(() => {
          new TWEEN.Tween(intersect.object.parent.rotation)
            .to(
              {
                x: intersect.object.parent.initialRotation.x,
                y: intersect.object.parent.initialRotation.y,
                z: intersect.object.parent.initialRotation.z
              },
              500
            )
            .easing(TWEEN.Easing.Quadratic.Out)
            .start()
        }, 100)
      }
    }

    document.addEventListener(
      'mousedown',
      () => {
        isDragging = true
      },
      false
    )

    const handleIntersect = (intersect) => {
      var randomAxis = new THREE.Vector3(
        Math.random() - 0.5,
        Math.random() - 0.5,
        Math.random() - 0.5
      ).normalize()
      const randomAngle = Math.PI * (Math.random() - 0.5)

      // eslint-disable-next-line
      const tweenMove = new TWEEN.Tween(intersect.object.parent.position)
        .to(
          {
            x: intersect.object.parent.position.x + Math.random() * 2 - 1,
            y: intersect.object.parent.position.y + Math.random() * 2 - 1,
            z: intersect.object.parent.position.z + Math.random() * 2 - 1
          },
          1500
        )
        .easing(TWEEN.Easing.Quadratic.Out)
        .onComplete(onCompleteMove(intersect))
        .start()

      // eslint-disable-next-line
      var tweenRotate = new TWEEN.Tween(intersect.object.parent.rotation)
        .to(
          {
            x: intersect.object.parent.rotation.x + randomAxis.x * randomAngle,
            y: intersect.object.parent.rotation.y + randomAxis.y * randomAngle,
            z: intersect.object.parent.rotation.z + randomAxis.z * randomAngle
          },
          2000
        )
        .easing(TWEEN.Easing.Quadratic.Out)
        .onComplete(onCompleteRotate(intersect))
        .start()
    }

    document.addEventListener(
      'mousemove',
      (e) => {
        const deltaMove = {
          x: e.offsetX - previousMousePosition.x,
          y: e.offsetY - previousMousePosition.y
        }

        if (isDragging) {
          const deltaRotationQuaternion = new THREE.Quaternion().setFromEuler(
            new THREE.Euler(
              toRadians(deltaMove.y * 0.25),
              toRadians(deltaMove.x * 0.25),
              0,
              'XYZ'
            )
          )

          parentGroup.quaternion.multiplyQuaternions(
            deltaRotationQuaternion,
            parentGroup.quaternion
          )
        }

        mouse.x = (e.clientX / window.innerWidth) * 2 - 1
        mouse.y = -(e.clientY / window.innerHeight) * 2 + 1

        raycaster.setFromCamera(mouse, camera)
        const intersects = raycaster.intersectObjects(
          parentGroup.children,
          true
        )

        if (intersects.length > 0) {
          for (let i = 0; i < intersects.length; i++) {
            handleIntersect(intersects[i])
          }
        }

        previousMousePosition = {
          x: e.offsetX,
          y: e.offsetY
        }
      },
      false
    )

    document.addEventListener(
      'mouseup',
      () => {
        isDragging = false
      },
      false
    )

    // eslint-disable-next-line
    const targetRotation = new THREE.Vector3(0, 0, 0)

    // animate the scene
    function animate() {
      requestAnimationFrame(animate)
      TWEEN.update()
      renderer.render(scene, camera)
    }

    animate()
    // eslint-disable-next-line
  }, [])

  return <div ref={canvasRef} />
}

export default OneAnimation
