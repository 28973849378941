import axios from 'axios'

const apiUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://99tzkt8tnj.execute-api.eu-central-1.amazonaws.com/production'
    : 'https://5hwlauin22.execute-api.eu-central-1.amazonaws.com/dev'
//   'http://localhost:3000/dev'

const sendEmail = async (email) => {
  try {
    const { data } = await axios.post(`${apiUrl}/send-email`, email)
    if (!data) return null
    return data
  } catch (err) {
    throw new Error(err)
  }
}

export default sendEmail
