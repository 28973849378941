import React from 'react'

import Lottie from 'react-lottie'
import PropTypes from 'prop-types'
import { StyledLoader } from './Loader.styled'
import cheilIconWhite from '../../assets/lotties/cheilIconWhite.json'

const Loader = ({ isInverted }) => {
  const defaultOptions = (emoji) => {
    return {
      loop: true,
      autoplay: true,
      animationData: emoji,
      speed: 2
    }
  }
  return (
    <StyledLoader
      __isInverted={isInverted}
      initial={{ scale: 1, opacity: 0 }}
      animate={{
        scale: 1,
        opacity: 1,
        transition: { duration: 0.4, ease: 'easeInOut' }
      }}
      exit={{
        scale: 1.6,
        opacity: 0,
        transition: { duration: 0.4, ease: 'easeInOut' }
      }}
    >
      <Lottie options={defaultOptions(cheilIconWhite)} />
    </StyledLoader>
  )
}

Loader.propTypes = {
  isInverted: PropTypes.bool
}

Loader.defaultProps = {
  isInverted: false
}

export default Loader
