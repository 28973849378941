import styled from 'styled-components'
import { Body1 } from 'assets/common/typography'
import { StyledCenteredContainer } from 'assets/common/mixin'

export const StyledNewsWrapper = styled(StyledCenteredContainer)`
  padding-top: 18rem;

  @media (max-width: 1024px) {
    padding-top: 13rem;
  }
`
export const StyledNewsContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    gap: -2rem;
  }
`

export const StyledNewsDescription = styled(Body1)`
  width: 40%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`
