import PropTypes from 'prop-types'
import { ReactComponent as BlockQuoteIcon } from 'assets/images/blockquote.svg'
import {
  StyledBlockQuote,
  StyledBlockQuoteTextContainer,
  StyledBlockQuoteText
} from './BlockQuote.styled'

const BlockQuote = ({ children }) => {
  return (
    <StyledBlockQuote>
      <BlockQuoteIcon />
      <StyledBlockQuoteTextContainer>
        <StyledBlockQuoteText>{children}</StyledBlockQuoteText>
      </StyledBlockQuoteTextContainer>
    </StyledBlockQuote>
  )
}

BlockQuote.propTypes = {
  children: PropTypes.node.isRequired
}

export default BlockQuote
