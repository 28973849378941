import styled, { css } from 'styled-components'

export const StyledDropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  z-index: 1;
  font-size: 1.125rem;

  @media (max-width: 900px) {
    align-self: flex-end;
  }
`

export const StyledDropdownMenu = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 0.4rem;
  list-style-type: none;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  padding: 0.5rem;
`

export const StyledDropdownMenuItem = styled.li`
  padding: 0.5rem 0.6rem;
  margin: 0.3rem;
  cursor: pointer;
  color: gray;

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: white;
      border-radius: 1rem;
      background: linear-gradient(
        83.1deg,
        #5ab5ea 8.18%,
        #a264d7 37.76%,
        #f3b243 62.4%,
        #ff557d 98.32%
      );
    `}

  &:hover {
    background: linear-gradient(
      83.1deg,
      #5ab5ea 8.18%,
      #a264d7 37.76%,
      #f3b243 62.4%,
      #ff557d 98.32%
    );
    border-radius: 1rem;
    color: white;
  }
`
