import styled from 'styled-components'
import { Body4 } from 'assets/common/typography'
import { colors } from 'assets/common/colors'
import { StyledCenteredContainer } from 'assets/common/mixin'

const { white, gray } = colors

export const StyledJobsWrapper = styled(StyledCenteredContainer)`
  padding: 18.625rem 20.5rem 10rem;

  @media (max-width: 1200px) {
    padding: 18.625rem 5rem 10rem;
  }

  @media (max-width: 900px) {
    padding: 18.625rem 3.75rem 10rem;
  }
  @media (max-width: 500px) {
    padding: 18.625rem 2rem 10rem;
  }
`

export const StyledJobsTitle = styled.h2`
  margin-top: 2rem;
`

export const StyledJobsSubTitleContainer = styled.div`
  color: ${gray};
  display: flex;
`

export const StyledJobsSubTitle = styled(Body4)``

export const StyledListItem = styled.li`
  font-size: 1.125rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.0225rem;
`

export const StyledLine = styled.hr`
  border: 1px solid #333;
  width: 20rem;
  margin: 2rem 0 1rem;
`

export const StyledJobContactContainer = styled.div`
  margin: 3rem 0;

  p {
    color: ${white};
  }
`
