import {
  CHANGE_IS_LOADING,
  SET_DATA,
  SHOW_CONTACT_FORM,
  SET_CONTACT_PERSON,
  SET_GEO_DATA
} from './actionNames'

export const setData = (data) => ({ type: SET_DATA, payload: data })

export const setGeoData = (geoInfo) => ({
  type: SET_GEO_DATA,
  payload: geoInfo
})
export const setContactPerson = (person) => ({
  type: SET_CONTACT_PERSON,
  payload: person
})

export const changeLoadingState = (state) => ({
  type: CHANGE_IS_LOADING,
  payload: state
})

export const showContactForm = (isShowing) => ({
  type: SHOW_CONTACT_FORM,
  payload: isShowing
})
