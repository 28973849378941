import styled from 'styled-components'
import { StyledCenteredContainer } from 'assets/common/mixin'

export const StyledMediaContainer = styled(StyledCenteredContainer)`
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 1.5625rem;

    img {
      width: 100%;
      height: auto;
    }
  }
`
