import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Body1 } from 'assets/common/typography'
import { getImageUrl } from 'sanityClient.js'
import {
  StyledClientsContainer,
  StyledClientsListContainer,
  StyledLogosContainer,
  StyledClientLogo
} from './AboutClientsSection.styled'

const AboutClients = ({ data: { client_list, client_title } }) => {
  const [displayedItems, setDisplayedItems] = useState([])

  useEffect(() => {
    const interval = setInterval(() => {
      const nextItems = client_list.slice(0, 3)
      setDisplayedItems(nextItems)
      client_list.push(client_list.shift()) // Move the first item to the end

      if (client_list.length < 3) {
        client_list.push(displayedItems[0]) // Repeat the first item
      }
    }, 3000)

    return () => {
      clearInterval(interval)
    }
  }, [client_list, displayedItems])

  return (
    <StyledClientsContainer>
      <StyledLogosContainer>
        <Body1>{client_title}</Body1>
        <StyledClientsListContainer>
          {displayedItems.map((item, index) => {
            return (
              <StyledClientLogo
                index={index}
                key={item._key}
                src={getImageUrl(item.icon.asset).url()}
                alt={item.name}
              />
            )
          })}
        </StyledClientsListContainer>
      </StyledLogosContainer>
    </StyledClientsContainer>
  )
}

AboutClients.propTypes = {
  data: PropTypes.shape({
    client_list: PropTypes.arrayOf(
      PropTypes.shape({
        _key: PropTypes.string.isRequired,
        icon: PropTypes.shape({
          asset: PropTypes.shape({
            url: PropTypes.func.isRequired
          }).isRequired
        }).isRequired,
        name: PropTypes.string.isRequired
      })
    ).isRequired,
    client_title: PropTypes.string.isRequired
  }).isRequired
}

export default AboutClients
