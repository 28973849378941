import styled from 'styled-components'
import { Body1, SubTitle1 } from 'assets/common/typography'
import { colors } from 'assets/common/colors'
import { StyledCenteredContainer } from 'assets/common/mixin'

const { white } = colors

export const StyledServicesIntroBackground = styled(StyledCenteredContainer)`
  color: ${white};
`

export const StyledServicesContainer = styled.div``

export const StyledServicesContent = styled.div`
  display: flex;
  align-items: baseline;
  gap: 3.75rem;
  margin-top: -3.125rem;

  @media (max-width: 1300px) {
    flex-direction: column;
    gap: 7rem;
  }
`

export const StyledServicesSubTitle = styled(Body1)`
  color: #555555;
  margin-bottom: 5rem;

  @media (max-width: 900px) {
    font-size: 0.9375rem;
  }
`

export const StyledServicesTitle = styled.h2`
  flex: 1 0 30%;

  @media (max-width: 900px) {
    font-size: 2rem;
    width: 60%;
    margin-bottom: 0;
  }
`

export const StyledServicesDescription = styled(SubTitle1)`
  flex: 1 0 40%;
  margin-top: 10.4rem;
  align-self: center;

  @media (max-width: 1300px) {
    font-size: 1.125rem;
    margin-top: -3rem;
  }
`

export const StyledServicesNavContainer = styled.div`
  flex: 1 0 20%;
  align-self: center;
  margin-top: 9rem;

  @media (max-width: 1300px) {
    margin-top: -2rem;
    align-self: flex-start;
  }
  @media (max-width: 900px) {
    margin: -4rem 0 5rem;
  }
`

export const StyledServicesNav = styled(Body1)`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  color: transparent;
  margin: 0.5rem 0;

  span {
    position: absolute;
    top: 0;
    left: 0;
    color: ${white};
    transition: all 0.5s ease-in-out;
    color: ${({ color }) => color};

    &:hover {
      transform: translateY(-100%);
    }

    &::after {
      content: ${({ content }) => `'${content}  → '`};
      display: block;
      position: absolute;
      top: 100%;
      width: 100%;
      height: 100%;
      color: ${white};
    }
  }
`
