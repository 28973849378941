import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { ParallaxProvider } from 'react-scroll-parallax'

import { Provider } from 'store'
import App from './App'
import reportWebVitals from './reportWebVitals'

import './assets/scss/main.scss'

window.global = window

// custom version of scroll to top that's instant, because smooth scrolling will very much break any other scrolling action
const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' // Optional if you want to skip the scrolling animation
    })
  }, [pathname])

  return null
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <ParallaxProvider>
          <Provider>
            <ScrollToTop />
            <App />
          </Provider>
        </ParallaxProvider>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
