import styled from 'styled-components'
import { colors } from 'assets/common/colors'
import { StyledCenteredContainer } from 'assets/common/mixin'

const { white } = colors

export const StyledNewsDetailsWrapper = styled(StyledCenteredContainer)`
  padding: 18rem 15rem 10rem;
  position: relative;

  @media (max-width: 1200px) {
    padding: 12.5rem 3.75rem;
  }

  @media (max-width: 500px) {
    padding: 12.5rem 2rem;
  }
`

export const StyledNewsTitle = styled.h3`
  margin-top: 2rem;
`

export const StyledNewsContactContainer = styled.div`
  p {
    color: ${white};
  }
`

export const StyledMoreUpdates = styled(StyledCenteredContainer)``

export const StyledLine = styled.hr`
  border: 1px solid ${white};
  width: 20rem;
  margin: 4rem 0;
`

export const StyledLongLine = styled.hr`
  border: 1px solid #333;
  position: relative;
  left: 50%;
  right: 50%;
  margin: 10rem -50vw -10rem -50vw;
  max-width: 100vw;
  width: 100vw;
  background: red;

  @media (min-width: 300px) and (max-width: 900px) {
    bottom: 2rem;
  }
`

export const StyledHeroMediaContainer = styled.div`
  margin: 4rem 0;
`
