import React from 'react'
import PropTypes from 'prop-types'
import { getFileUrl } from 'sanityClient.js'
import {
  StyledMediaBlockContainer,
  StyledVideoContainer
} from './MediaBlock.styled'

const MediaBlock = ({ value: { videos, video_style } }) => {
  if (!videos) return null

  return (
    <StyledMediaBlockContainer videoStyle={video_style} amount={videos.length}>
      {videos.map((video) => (
        <StyledVideoContainer
          preload="true"
          playsInline
          muted
          loop
          controls
          key={video._key}
        >
          <source src={getFileUrl(video.asset._ref)} type="video/mp4" />
        </StyledVideoContainer>
      ))}
    </StyledMediaBlockContainer>
  )
}

MediaBlock.propTypes = {
  value: PropTypes.shape({
    videos: PropTypes.arrayOf(
      PropTypes.shape({
        asset: PropTypes.shape({
          _ref: PropTypes.string.isRequired,
          _type: PropTypes.string.isRequired
        })
      })
    ).isRequired,
    video_style: PropTypes.string.isRequired,
    _key: PropTypes.string.isRequired
  }).isRequired
}

export default MediaBlock
