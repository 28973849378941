import React from 'react'
import PropTypes from 'prop-types'
import { PortableText } from '@portabletext/react'
import { ImageBlock } from 'components'
import { StyledImagesContainer } from './ImagesBlock.styled'

const ImagesBlock = ({ value: { images, image_style, _key } }) => {
  if (!images) return null

  return (
    <StyledImagesContainer
      key={_key}
      imageStyle={image_style}
      amount={images.length}
    >
      {images.map((image) => (
        <div key={image._key}>
          <PortableText
            value={image}
            components={{
              types: {
                image: ImageBlock
              }
            }}
          />
        </div>
      ))}
    </StyledImagesContainer>
  )
}

ImagesBlock.propTypes = {
  value: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        asset: PropTypes.shape({
          _ref: PropTypes.string.isRequired,
          _type: PropTypes.string.isRequired
        })
      })
    ).isRequired,
    image_style: PropTypes.string.isRequired,
    _key: PropTypes.string.isRequired
  }).isRequired
}

export default ImagesBlock
